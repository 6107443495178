import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Button, Fab, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ABBREVIATED_TIMEZONE_FORMAT, DAY_MONTH_TIMEZONE_FORMAT } from '../../helpers/time';
import { stripAppointmentTypeFromAppointmentName } from '../../lib/format';
import {
  AppointmentTypeSlotWithProvider,
  AppointmentTypeWithAvailableSlots,
} from '../../lib/scheduling/types';
import { getAuth } from '../../lib/storage';
import MixpanelClient, { TrackingEventV2 } from '../../lib/tracking/mixpanel';
import CoreLayout from '../Layout/CoreLayout';
import { DefaultGroupClassCoverImage } from './../../assets/images';
import GroupClassConfirmation from './GroupClassConfirmation';
import SuccessfulRegistration from './SuccessfulRegistration';
import VisitWarning from './components/VisitWarning';
import './group-class-detail.css';

type BookingState =
  | { status: 'selecting-slot'; selectedSlot: null }
  | {
      status: 'booking-class' | 'successful-registration';
      selectedSlot: AppointmentTypeSlotWithProvider;
    };

const GroupClassDetail: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [bookingState, setBookingState] = useState<BookingState>({
    status: 'selecting-slot',
    selectedSlot: null,
  });
  const [detail, setDetail] = useState<AppointmentTypeWithAvailableSlots | null>(null);

  const handleSelectSlot = useCallback(
    (slot: AppointmentTypeSlotWithProvider, index: number) => {
      MixpanelClient.trackEvent({
        eventName: TrackingEventV2.GroupClassSelectClassSlot,
        properties: {
          source: 'class-list',
          appointmentName: detail?.name,
          slotIndex: index,
        },
      });
      setBookingState({ status: 'booking-class', selectedSlot: slot });
    },
    [detail]
  );

  const handleSuccessRegistration = useCallback(
    (slot: AppointmentTypeSlotWithProvider) => {
      MixpanelClient.trackEvent({
        eventName: TrackingEventV2.GroupClassRegisterConfirmed,
        properties: {
          source: 'class-list',
          appointmentName: detail?.name,
        },
      });
      setBookingState({ status: 'successful-registration', selectedSlot: slot });
    },
    [detail]
  );

  const handleGoToSelectSlot = useCallback(() => {
    MixpanelClient.trackEvent({
      eventName: TrackingEventV2.GroupClassListClassSlot,
      properties: {
        source: 'class-list',
        appointmentName: detail?.name,
      },
    });
    setBookingState({ status: 'selecting-slot', selectedSlot: null });
  }, [detail]);

  const handleGoToSelectGroupClass = useCallback(() => {
    const auth = getAuth();
    navigate(`/group-classes/${auth?.token}`);
  }, [navigate]);

  useEffect(() => {
    const appointments = location.state as AppointmentTypeWithAvailableSlots | undefined;

    // checking main data
    if (!appointments || !appointments.name || appointments.slots.length === 0) {
      return navigate(`/error`);
    }
    MixpanelClient.trackEvent({
      eventName: TrackingEventV2.GroupClassDetail,
      properties: { source: 'class-detail', appointmentName: appointments.name },
    });

    setDetail(appointments);
  }, [location, navigate]);

  if (bookingState.status === 'booking-class') {
    return (
      <CoreLayout logo={false}>
        <GroupClassConfirmation
          appointmentType={{
            id: detail!.id,
            code: detail!.code,
            duration: detail!.duration,
            name: detail!.name,
            countAsChargeableVisit: detail!.countAsChargeableVisit,
          }}
          booking={{ time: bookingState.selectedSlot.time }}
          provider={{
            calendarId: bookingState.selectedSlot.provider.calendarId,
            firstName: bookingState.selectedSlot.provider.firstName,
            lastName: bookingState.selectedSlot.provider.lastName,
          }}
          onNext={() => handleSuccessRegistration(bookingState.selectedSlot)}
          onGoBack={handleGoToSelectSlot}
        />
      </CoreLayout>
    );
  }

  if (bookingState.status === 'successful-registration') {
    return (
      <CoreLayout logo={false}>
        <SuccessfulRegistration
          appointmentType={{ name: detail!.name }}
          booking={{ time: bookingState.selectedSlot.time }}
          onClose={handleGoToSelectGroupClass}
        />
      </CoreLayout>
    );
  }

  return (
    <CoreLayout logo={false}>
      <Box className='group-main-container'>
        <header className='group-class-header'>
          <img
            src={detail?.image ? detail?.image : DefaultGroupClassCoverImage}
            alt='header-icon'
          />
        </header>

        <div className='group-content-container'>
          <Typography variant='h3' className='group-class-title'>
            {stripAppointmentTypeFromAppointmentName(detail?.name) || ''}
          </Typography>

          {detail?.tags && (
            <div className='group-class-tag-container'>
              {detail.tags.map((tag, index) => {
                return (
                  <div className='group-class-pill' key={`${tag}-${index}`}>
                    <label className='group-class-tag'>{tag}</label>
                  </div>
                );
              })}
            </div>
          )}

          <div className='group-class-description'>{detail?.description || ''}</div>

          {detail?.countAsChargeableVisit && <VisitWarning />}

          <Stack direction={'column'} alignItems={'center'} spacing={2} sx={{ width: '95%' }}>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }}>
              <Typography variant='h3'>Available date</Typography>
              <Typography>
                Time shown in {DateTime.now().toFormat(ABBREVIATED_TIMEZONE_FORMAT)}
              </Typography>
            </Stack>

            {detail?.slots.map((slot, index) => {
              return (
                <Button
                  variant={'contained'}
                  className='appointment-btn group-class-btn'
                  key={`slot-${slot.time}`}
                  onClick={() => handleSelectSlot(slot, index)}>
                  {DateTime.fromISO(slot.time).toFormat(DAY_MONTH_TIMEZONE_FORMAT)}{' '}
                  <label className='group-class-btn-provider-name'>
                    {`${slot.provider.firstName || 'Unknown'} ${slot.provider.lastName || ''}`}
                  </label>
                </Button>
              );
            })}
          </Stack>
        </div>
        <Fab className='fab-action' size='small' onClick={() => navigate(-1)}>
          <ChevronLeftIcon fontSize='small' />
        </Fab>
        <br />
      </Box>
    </CoreLayout>
  );
};

export default GroupClassDetail;
