import { Box, Stack } from '@mui/material';
import { DefaultMeetingSession } from 'amazon-chime-sdk-js';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  ELAPSED_MS_BEFORE_AUTO_MARKING_AS_ATTENDED,
  TOAST_AUTO_CLOSE_DELAY_MS,
} from '../../../config/config';
import { useFeatures } from '../../../contexts/features/context';
import { useVideoCall } from '../../../contexts/videoCall/context';
import { logErrorAndReportToHoneybadger } from '../../../lib/errorReporting';
import { Features } from '../../../lib/features/client';
import FeatureManager from '../../../lib/features/manager';
import telemedicineServiceClient from '../../../lib/telemedicine/client';
import { VideoCallStatus } from '../../../lib/telemedicine/types';
import MixpanelClient, { TrackingEvent, TrackingEventV2 } from '../../../lib/tracking/mixpanel';
import { UserType } from '../../../lib/video/types';
import Toast from '../../Toast/Toast';
import Outline from '../../VideoCall/components/Outline/Outline';
import RecordingDialog from '../../VideoCall/components/RecordingDialog/RecordingDialog';
import { RoomContainer } from '../../VideoCall/components/RoomContainer';
import { VideoController } from '../VideoController';
import '../chime-video-call.css';
import { Participant } from '../meeting-call.types';
import Toolbox, {
  ClickOptionCallbackParams,
  RefToolbox,
  ToolBoxState,
  ToolboxAction,
} from './Toolbox/Toolbox';
import VideoTrack from './VideoComponents/VideoTrack';
import WaitingForParticipant from './WaitingForParticipant';

type EnaraMeetingProps = {
  loadingMode: boolean;
  onLeaveRoom: (meetingId: string) => void;
};

const EnaraMeeting: FC<EnaraMeetingProps> = ({ loadingMode, onLeaveRoom }) => {
  const [outlineOpen, setOutlineOpen] = useState(false);
  const [recordingDialogOpen, setRecordingDialogOpen] = useState(false);
  const [meetingSession, setMeetingSession] = useState<DefaultMeetingSession | null>(null);
  const [localParticipant, setLocalParticipant] = useState<Participant | null>(null);
  const [participants, setParticipants] = useState<Participant[]>([]);

  const didParticipantAttendedOrReschedule = useRef<boolean>(false);
  const autoMarkAsAttendedTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const toolboxRef = useRef<RefToolbox | null>(null);
  const autoMarkAsAttendedAlreadyScheduled = useRef(false);
  const [someIsSharingScreen, setSomeIsSharingScreen] = useState(false);

  const { videoCallState, getParticipantNameBy } = useVideoCall();
  const { meetingInfo } = videoCallState;

  const [toolboxState, setToolboxState] = useState<ToolBoxState>({
    isAudioOn: true,
    isVideoOn: true,
    isSharingScreen: false,
    isRecording: false,
  });

  const { featuresState } = useFeatures();

  const participantsObserverHandler = {
    localParticipantUpdated(participant: Participant) {
      if (!localParticipant) {
        if (meetingInfo?.userType !== UserType.Guest) {
          participant.name = getParticipantNameBy(meetingInfo?.userType ?? UserType.Guest);
        }

        setLocalParticipant(participant);
      }
    },
    remoteParticipantsUpdated(remoteParticipants: Participant[]) {
      let sharingScreen = false;
      let participantFound = null;

      for (const participant of remoteParticipants) {
        if (!participantFound && !participant.tileState.boundExternalUserId?.includes('guest')) {
          participantFound = participant;
        }

        if (participant.tileState?.isContent) {
          sharingScreen = true;
        }
      }

      setSomeIsSharingScreen(sharingScreen);

      if (participantFound) {
        if (!participantFound.name && meetingInfo?.userType !== UserType.Guest) {
          const name = getParticipantNameBy(
            meetingInfo?.userType === UserType.Provider ? UserType.Member : UserType.Provider
          );

          participantFound.name = name;
        }
      }

      setParticipants([...remoteParticipants]);
      scheduleAutoMarkAsAttendedForProvider(remoteParticipants);
    },
  };

  const videoController = useMemo(
    () =>
      new VideoController(
        meetingInfo!.videoConfig?.meeting,
        meetingInfo!.videoConfig?.attendee.Attendee,
        participantsObserverHandler
      ),
    []
  );

  const initializeVideoIO = async () => {
    const setupResult = await videoController.startSetup();
    if (!setupResult.success) {
      Toast.error(`${setupResult.message}`);
      return;
    }

    const session = setupResult.session;
    setMeetingSession(session!);
    session!.audioVideo.start();
  };

  useEffect(() => {
    initializeVideoIO();

    return () => {
      if (!meetingSession) {
        return;
      }

      (async () => {
        await videoController?.unbindVideoIO();
      })();
    };
  }, []);

  const scheduleAutoMarkAsAttendedForProvider = (participants: Participant[]): void => {
    if (meetingInfo?.userType !== UserType.Provider) {
      return;
    }

    if (
      !didParticipantAttendedOrReschedule.current &&
      participants.length > 0 &&
      !autoMarkAsAttendedAlreadyScheduled.current
    ) {
      autoMarkAsAttendedTimeoutId.current = setTimeout(async () => {
        const result = await updateVideoCallStatus(VideoCallStatus.Attended);

        if (result) {
          didParticipantAttendedOrReschedule.current = true;

          Toast.info('Attendance confirmed', { autoClose: TOAST_AUTO_CLOSE_DELAY_MS });

          MixpanelClient.trackEvent({
            eventName: TrackingEvent.Task,
            properties: { field: 'Auto-mark as Attended', source: 'chime-video' },
          });

          toolboxRef.current?.setMarkedAsAttended();
        }
      }, ELAPSED_MS_BEFORE_AUTO_MARKING_AS_ATTENDED);
      autoMarkAsAttendedAlreadyScheduled.current = true;
    }
  };

  const endCall = () => {
    if (
      meetingInfo?.userType === UserType.Provider &&
      !didParticipantAttendedOrReschedule.current
    ) {
      return Toast.warning(
        `Before ending the call make sure to "Mark as Attended" or "Mark as Reschedule"`,
        {
          position: 'bottom-center',
          className: 'tool-box-toast-center',
        }
      );
    }

    cleanUpAndLeaveRoom();
  };

  const cleanUpAndLeaveRoom = async () => {
    if (autoMarkAsAttendedTimeoutId.current !== null) {
      clearTimeout(autoMarkAsAttendedTimeoutId.current);
    }

    onLeaveRoom(meetingInfo!.videoConfig?.meeting.MeetingId);
    await videoController?.unbindVideoIO();
  };

  const updateVideoCallStatus = async (videoStatus: VideoCallStatus) => {
    if (meetingInfo?.userType !== UserType.Provider) {
      return false;
    }

    const appointmentId = meetingInfo.appointment?.id;
    const { provider } = meetingInfo.meetingAttendees ?? {};

    if (!appointmentId) {
      Toast.error('Could not find an appointment for the member', {
        position: 'bottom-right',
        className: 'tool-box-toast-right',
      });
      return false;
    }
    if (!provider?.id || !provider.authToken) {
      Toast.error('Could not get the provider info', {
        position: 'bottom-right',
        className: 'tool-box-toast-right',
      });
      return false;
    }

    try {
      await telemedicineServiceClient.setVideoCallStatusTo({
        scheduleId: appointmentId,
        providerId: provider.id,
        providerAuthToken: provider.authToken,
        videoCallStatus: videoStatus,
      });
    } catch (error) {
      Toast.error('Could not update video call status', {
        position: 'bottom-right',
        className: 'tool-box-toast-right',
      });
      logErrorAndReportToHoneybadger({ error });
      return false;
    }
    return true;
  };

  const handleVideoCallStatusUpdate = async (status: VideoCallStatus) => {
    didParticipantAttendedOrReschedule.current = true;

    const result = await updateVideoCallStatus(status);

    if (result) {
      Toast.info(
        status === VideoCallStatus.Rescheduled ? 'Marked as Reschedule' : 'Marked as Attended',
        {
          position: 'bottom-right',
          className: 'tool-box-toast-right',
        }
      );
    }

    return result;
  };

  const handleClickToolboxOption = async (params: ClickOptionCallbackParams) => {
    if (!meetingSession) {
      return false;
    }

    const { action } = params;
    switch (action) {
      case ToolboxAction.StartRecording:
        setRecordingDialogOpen(!recordingDialogOpen);
        MixpanelClient.trackEvent({
          eventName: TrackingEventV2.VideoCallStartRecording,
          properties: { field: 'Record Meeting' },
        });
        return true;

      case ToolboxAction.ScreenSharing:
        if (toolboxState.isSharingScreen) {
          meetingSession.audioVideo.stopContentShare();
        } else {
          await meetingSession.audioVideo.startContentShareFromScreenCapture();
        }

        setToolboxState((previousState) => {
          return {
            ...previousState,
            isSharingScreen: !previousState.isSharingScreen,
          };
        });
        return true;

      case ToolboxAction.ToggleTrackVideo:
        if (toolboxState.isVideoOn) {
          await videoController?.stopVideo();
        } else {
          await videoController?.startVideo();
        }

        setToolboxState((previousState) => {
          return {
            ...previousState,
            isVideoOn: !previousState.isVideoOn,
          };
        });
        return true;

      case ToolboxAction.ToggleTrackAudio:
        if (toolboxState.isAudioOn) {
          meetingSession.audioVideo.realtimeMuteLocalAudio();
        } else {
          meetingSession.audioVideo.realtimeUnmuteLocalAudio();
        }

        setToolboxState((previousState) => {
          return {
            ...previousState,
            isAudioOn: !previousState.isAudioOn,
          };
        });
        return true;

      case ToolboxAction.LeaveRoom:
        endCall();
        return true;

      case ToolboxAction.VideoCallStatus:
        return handleVideoCallStatusUpdate(params.status);

      case ToolboxAction.ShowOutline:
        setOutlineOpen(true);
        MixpanelClient.trackEvent({
          eventName: TrackingEventV2.VideoCallOutlineOpen,
          properties: { field: 'Show Outline' },
        });
        return true;

      default:
        logErrorAndReportToHoneybadger({
          error: `[handleClickToolboxOption] Unknown action: ${action}`,
        });
    }

    return false;
  };

  const handleOnRecordingConfirm = async () => {
    setToolboxState((previousState) => {
      return {
        ...previousState,
        isRecording: true,
      };
    });
  };

  const handleOutlineClose = () => {
    setOutlineOpen(false);
    MixpanelClient.trackEvent({
      eventName: TrackingEventV2.VideoCallOutlineClose,
      properties: { field: 'Close Outline' },
    });
  };

  const renderShareScreenView = () => {
    return (
      <>
        {/* Screen share container */}
        <Stack className='chime-video-call-container__screen-share'>
          {participants.map(
            (tileParticipant, index) =>
              tileParticipant?.tileState?.isContent && (
                <VideoTrack
                  participant={tileParticipant}
                  session={meetingSession}
                  key={`${index}-${tileParticipant.tileState.boundExternalUserId}`}
                  className='chime-video-call-container__video-track--content'
                  isScreenShare={true}
                />
              )
          )}
        </Stack>
        {/* Participant videos */}
        <Stack
          sx={{ flexDirection: 'row' }}
          className='chime-video-call-container__participant-videos'>
          {participants.map(
            (tileParticipant, index) =>
              !tileParticipant?.tileState?.isContent && (
                <VideoTrack
                  participant={tileParticipant}
                  session={meetingSession}
                  key={`${tileParticipant.tileState.boundExternalUserId}`}
                  className='chime-video-call-container__video-track--remote'
                />
              )
          )}
          {localParticipant && (
            <VideoTrack
              participant={localParticipant}
              session={meetingSession}
              className='chime-video-call-container__video-track--local'
            />
          )}
        </Stack>
      </>
    );
  };

  const renderOneOnOneView = () => {
    return (
      <>
        <Stack className='chime-video-call-container__participants'>
          {participants.length === 0 ? (
            <Stack className='chime-video-call-container__waiting'>
              <WaitingForParticipant
                numberOfRemoteParticipants={participants.length}
                onMarkCallAsNoShow={cleanUpAndLeaveRoom}
              />
            </Stack>
          ) : (
            <Stack className='chime-video-call-container__remote-video'>
              {participants.map((tileParticipant, index) => (
                <VideoTrack
                  participant={tileParticipant}
                  session={meetingSession}
                  key={`${tileParticipant.tileState.boundExternalUserId}`}
                  className='chime-video-call-container__video-track--remote'
                />
              ))}
            </Stack>
          )}

          <Stack className='chime-video-call-container__local-videos'>
            {localParticipant && (
              <VideoTrack
                participant={localParticipant}
                session={meetingSession}
                className='chime-video-call-container__video-track--local'
              />
            )}
          </Stack>
        </Stack>
      </>
    );
  };

  return (
    <Box
      className={`chime-video-call-container ${
        someIsSharingScreen ? 'app-chime--screen-share' : ''
      }`}>
      <RoomContainer open={outlineOpen}>
        {someIsSharingScreen ? renderShareScreenView() : renderOneOnOneView()}
      </RoomContainer>

      {FeatureManager.shouldRenderRecordingForAppointment({
        feature: featuresState[Features.MembersWebAppShowRecording],
        userType: meetingInfo?.userType ?? UserType.Guest,
        appointmentType:
          meetingInfo && 'appointment' in meetingInfo ? meetingInfo.appointment?.type : undefined,
      }) && (
        <RecordingDialog
          isRecording={toolboxState.isRecording}
          isOpen={recordingDialogOpen}
          onConfirm={() => handleOnRecordingConfirm()}
          onClose={() => setRecordingDialogOpen(false)}
        />
      )}

      {FeatureManager.shouldRenderOutlineForAppointment({
        feature: featuresState[Features.MembersWebAppShowOutline],
        userType: meetingInfo?.userType ?? UserType.Guest,
        appointmentType:
          meetingInfo && 'appointment' in meetingInfo ? meetingInfo.appointment?.type : undefined,
      }) && <Outline isOpen={outlineOpen} onClose={handleOutlineClose} />}

      <Toolbox
        ref={toolboxRef}
        userType={meetingInfo?.userType ?? UserType.Guest}
        toolboxState={toolboxState}
        loadingMode={loadingMode}
        onClickOption={handleClickToolboxOption}
      />
    </Box>
  );
};

export default EnaraMeeting;
