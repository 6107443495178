import CloseIcon from '@mui/icons-material/Close';
import { Button, Fab, Link, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { SuccessRegistration } from '../../assets/images';
import { getMilitaryTime, getReadableDate } from '../../helpers/time';
import './successful-registration.css';

type Props = {
  appointmentType: {
    name: string;
  };
  booking: {
    time: string;
  };
  onClose: () => void;
};

const SuccessfulRegistration: FC<Props> = ({ appointmentType, booking, onClose }) => {
  return (
    <Stack className='successful-registration-page'>
      <Stack className='main-content'>
        <Stack>
          <img src={SuccessRegistration} alt='header-icon' />
        </Stack>
        <Typography>Registration completed to</Typography>
        <Typography variant='h3' component='p'>
          {appointmentType.name}
        </Typography>
        <Typography>
          See you on {getReadableDate(booking.time)} at {getMilitaryTime(booking.time)}
        </Typography>
        <Typography>Manage Group Classes in the Enara App</Typography>
        <Link href='#' underline='none' onClick={onClose}>
          Close this window
        </Link>
      </Stack>
      <Paper className='actions' elevation={10}>
        <Button variant='contained' onClick={onClose}>
          Register Another Class
        </Button>
      </Paper>
      <Fab className='fab-action' size='small' onClick={onClose}>
        <CloseIcon fontSize='small' />
      </Fab>
    </Stack>
  );
};

export default SuccessfulRegistration;
