import { DateTime } from 'luxon';

export const DATE_TIME_MONTH_FORMAT = 'yyyy-MM';
export const DATE_TIME_DAY_FORMAT = 'yyyy-MM-dd'; // 2021-01-01
export const DATE_TIME_DAY_TITLE_FORMAT = 'EEEE, LLL dd'; // Monday, Jan 01
export const DATE_TIME_TIME_FORMAT = 'h:mm a'; // 12:00 pm
export const DATE_TIME_MILITARY_TIME_FORMAT = 'h:mm a ZZZZ';
export const DATE_TIME_MONTH_NAME_ONLY_FORMAT = 'MMM';
export const DATE_TIME_DAY_ONLY_FORMAT = 'd';
export const DATE_TIME_DAY_NAME_AND_HOUR_FORMAT = 'EEEE, t';
export const DATE_TIMEZONE = 'ZZZZZ';
export const ABBREVIATED_TIMEZONE_FORMAT = 'ZZZZ';
export const DAY_MONTH_TIMEZONE_FORMAT = 'EEE DD t';

export function getReadableDate(dateAsString: string) {
  // Luxon converts date to local timezone automatically
  return DateTime.fromISO(dateAsString).toFormat('cccc, LLL d, yyyy');
}

export function getMilitaryTime(dateAsString: string) {
  // Luxon converts date to local timezone automatically
  return DateTime.fromISO(dateAsString).toFormat(DATE_TIME_MILITARY_TIME_FORMAT);
}
