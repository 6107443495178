import { Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { DATE_TIME_DAY_TITLE_FORMAT, DATE_TIME_MILITARY_TIME_FORMAT } from '../../helpers/time';
import { AcuityAppointment, MonthlyAvailability } from '../../lib/scheduling/types';
import { CALENDAR_ICON, CLOCK_ICON } from '../icons/unicode';

type Props = {
  appointment: AcuityAppointment | null;
  appointmentType?: MonthlyAvailability['appointmentType'];
};

const BookingResult: FC<Props> = ({ appointment, appointmentType }) => {
  const appointmentTypeName = appointmentType?.officialName ?? '';
  return (
    <>
      {appointment ? (
        <>
          <Typography variant='h3' textAlign={'center'} className='schedule-selection-title'>
            You have successfully booked your appointment
          </Typography>
          <div className='booking-appointment-box'>
            <Typography variant='h4' marginBottom={2}>
              {appointmentTypeName} with <br />
              {`${appointment.provider?.providerFirstName || ''} ${
                appointment.provider?.providerLastName || ''
              }`}
            </Typography>
            <div className='booking-datetime-container'>
              <Typography variant='h4'>{String.fromCodePoint(CALENDAR_ICON)}</Typography>
              <Typography variant='h4'>
                {DateTime.fromISO(appointment.datetime).toFormat(DATE_TIME_DAY_TITLE_FORMAT)}
              </Typography>
            </div>
            <div className='booking-datetime-container'>
              <Typography variant='h4'>{String.fromCodePoint(CLOCK_ICON)}</Typography>
              <Typography variant='h4'>
                {DateTime.fromISO(appointment.datetime).toFormat(DATE_TIME_MILITARY_TIME_FORMAT)}
              </Typography>
            </div>
          </div>
          <div className='booking-messages-container'>
            <Typography variant='h4' className='booking-messages'>
              You can manage your upcoming appointments in the Enara app.
            </Typography>
            <Typography variant='h4' className='booking-messages'>
              You may close this window now.
            </Typography>
          </div>
        </>
      ) : (
        <Stack className='booking-error-container' spacing={5}>
          <Typography variant='h2'>Apologies</Typography>
          <Typography variant='h4' className='booking-error-message'>
            We’re unable to book your appointment at the moment.
          </Typography>
          <Typography variant='h3' style={{ textAlign: 'center' }}>
            Please reach out to our support team for help.
          </Typography>
          <Typography variant='h3' style={{ textAlign: 'center' }}>
            We apologize for any inconvenience this may have caused.
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default BookingResult;
