import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { Box, Button, CircularProgress, Fab, Paper, Stack, Typography } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { getMilitaryTime, getReadableDate } from '../../helpers/time';
import { stripAppointmentTypeFromAppointmentName } from '../../lib/format';
import schedulingClient from '../../lib/scheduling/client';
import { Location } from '../../lib/scheduling/types';
import MixpanelClient, { TrackingEventV2 } from '../../lib/tracking/mixpanel';
import Toast from '../Toast/Toast';
import VisitWarning from './components/VisitWarning';
import './group-class-confirmation.css';

type Props = {
  appointmentType: {
    id: number;
    code?: string;
    duration: number;
    name: string;
    countAsChargeableVisit: boolean;
  };
  booking: {
    time: string;
  };
  provider: {
    calendarId: number;
    firstName: string;
    lastName: string;
  };
  onNext: () => void;
  onGoBack: () => void;
};

const GroupClassConfirmation: FC<Props> = ({
  appointmentType,
  booking,
  provider,
  onNext,
  onGoBack,
}) => {
  const [bookingGroupClass, setBookingGroupClass] = useState(false);

  const providerName = useMemo(() => {
    const { firstName, lastName } = provider;
    return `${firstName} ${lastName ?? ''}`;
  }, [provider]);

  const appointmentFormat = useMemo(() => {
    const { code } = appointmentType;
    return code?.split('-')?.[2] === Location.InPerson ? 'In person' : 'Virtual';
  }, [appointmentType]);

  const handleConfirm = async () => {
    MixpanelClient.trackEvent({
      eventName: TrackingEventV2.GroupClassRegister,
      properties: { field: 'Confirm Registration' },
    });

    const { id: appointmentTypeId } = appointmentType;
    const { calendarId } = provider;
    const { time: dateTime } = booking;
    setBookingGroupClass(true);
    const result = await schedulingClient.createAppointment({
      appointmentTypeId,
      calendarId,
      dateTime,
    });
    if (!!result) {
      onNext();
    } else {
      Toast.error('Could not book your group class');
    }
    setBookingGroupClass(false);
  };

  return (
    <Stack className='group-class-confirmation-page'>
      <Stack className='main-content'>
        <Box className='title'>
          <Typography variant='h2' component='h2'>
            Confirm your Class
          </Typography>
        </Box>
        <Stack className='content'>
          <Stack className='appointment-type-name'>
            <Typography variant='h3' component='p'>
              {stripAppointmentTypeFromAppointmentName(appointmentType.name)}
            </Typography>
          </Stack>
          <Stack className='appointment-type-host'>
            <Typography variant='h4' component='p'>
              Hosted by:
            </Typography>
            <Typography>{providerName}</Typography>
          </Stack>
          <Stack className='appointment-type-details'>
            <Box className='item'>
              <CalendarTodayIcon />
              <Typography>{getReadableDate(booking.time)}</Typography>
            </Box>
            <Box className='item'>
              <WatchLaterIcon />
              <Typography>{getMilitaryTime(booking.time)}</Typography>
            </Box>
            <Box className='item'>
              <TimelapseIcon />
              <Typography>{appointmentType.duration} Minutes</Typography>
            </Box>
            <Box className='item'>
              <LocationOnIcon />
              <Typography>{appointmentFormat}</Typography>
            </Box>
          </Stack>
          {appointmentType.countAsChargeableVisit && (
            <Stack className='appointment-type-warning'>
              <VisitWarning />
            </Stack>
          )}
        </Stack>
      </Stack>
      <Paper className='actions' elevation={10}>
        <Button variant='contained' onClick={handleConfirm} disabled={bookingGroupClass}>
          {bookingGroupClass ? <CircularProgress size={24} /> : 'Confirm Registration'}
        </Button>
      </Paper>
      <Fab className='fab-action' size='small' onClick={onGoBack}>
        <ChevronLeftIcon fontSize='small' />
      </Fab>
    </Stack>
  );
};

export default GroupClassConfirmation;
