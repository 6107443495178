import { FC, PropsWithChildren } from 'react';
import EnaraLogo from '../icons/EnaraLogo';
import './core-layout.css';

const CoreLayout: FC<PropsWithChildren & { logo?: false }> = ({ children, logo }) => {
  return (
    <div className='core-container'>
      {logo !== false ? <EnaraLogo /> : null}
      {children}
    </div>
  );
};

export default CoreLayout;
