import { SCHEDULE_API_BASE_URL } from '../environment';
import HttpRequest from '../request';
import { getAppointmentTagForCurrentSession, getAuth } from '../storage';
import {
  AcuityAppointment,
  AcuityCancelAppointmentSource,
  AppointmentTypeWithAvailableSlots,
  DailyAvailability,
  DecryptedSchedulingLink,
  DecryptedSchedulingLinkResponse,
  Location,
  MonthlyAvailability,
  Specialty,
} from './types';

class Scheduling extends HttpRequest {
  private getAvailabilityParams(
    appointmentTypeId: number | null,
    specialty: Specialty | null
  ): { appointmentTypeId: number } | { specialty: Specialty; locationType: Location } {
    if (!appointmentTypeId && !specialty) {
      throw new Error(
        'Cannot pull monthly availability. Either appointment type ID or specialty is required'
      );
    }

    if (appointmentTypeId) {
      return { appointmentTypeId };
    }

    return { specialty: specialty!, locationType: Location.Online };
  }

  public getSpecialtyParamFromURL(): Specialty | null {
    const specialtyParamAsUpperCase = new URLSearchParams(window.location.search)
      .get('specialty')
      ?.toUpperCase();

    if (!specialtyParamAsUpperCase) {
      return null;
    }

    return (
      [Specialty.Exercise, Specialty.Medical, Specialty.Metabolism, Specialty.Nutritional].find(
        (specialty) => specialty === specialtyParamAsUpperCase
      ) || null
    );
  }

  public getAppointmentTypeIdParamFromURL(): number | null {
    const appointmentTypeId = new URLSearchParams(window.location.search).get('appointmentTypeId');

    if (!appointmentTypeId) {
      return null;
    }

    return Number(appointmentTypeId);
  }

  public async getMonthlyAvailability({
    appointmentTypeId,
    specialty,
    month,
  }: {
    appointmentTypeId: number | null;
    specialty: Specialty | null;
    month: string;
  }): Promise<MonthlyAvailability | null> {
    const monthlyAvailabilityResponse = await this.get<{ success: boolean } & MonthlyAvailability>({
      url: `/v2/members/${getAuth()?.id}/appointments/follow-up/available-dates`,
      params: { ...this.getAvailabilityParams(appointmentTypeId, specialty), month },
      options: { authAs: 'header' },
    });

    if (monthlyAvailabilityResponse) {
      const { success, ...rest } = monthlyAvailabilityResponse;

      return rest;
    }

    return null;
  }

  public async getDailyAvailability({
    appointmentTypeId,
    specialty,
    date,
  }: {
    appointmentTypeId: number | null;
    specialty: Specialty | null;
    date: string;
  }): Promise<DailyAvailability | null> {
    const dailyAvailabilityResponse = await this.get<{ success: boolean } & DailyAvailability>({
      url: `/v2/members/${getAuth()?.id}/appointments/follow-up/available-times`,
      params: { ...this.getAvailabilityParams(appointmentTypeId, specialty), date },
      options: { authAs: 'header' },
    });

    if (dailyAvailabilityResponse) {
      const { success, ...rest } = dailyAvailabilityResponse;

      return rest;
    }

    return null;
  }

  public async createAppointment({
    appointmentTypeId,
    dateTime,
    calendarId,
  }: {
    appointmentTypeId: number;
    dateTime: string;
    calendarId: number;
  }): Promise<AcuityAppointment | null> {
    return await this.post<AcuityAppointment>({
      url: `schedule/v1/calendars/${calendarId}/appointments?shouldNotAssignProvider=true`,
      payload: { appointmentTypeId, datetime: dateTime, tag: getAppointmentTagForCurrentSession() },
      options: { authAs: 'header' },
    });
  }
  public async cancelAppointment({
    memberId,
    appointmentId,
  }: {
    memberId: number;
    appointmentId: number;
  }): Promise<number | null> {
    const cancelAppointmentResponse = await this.put<{ cancelledAppointment: { id: number } }>({
      url: `/v1/members/${memberId}/appointments/${appointmentId}/cancel`,
      payload: { source: AcuityCancelAppointmentSource.MemberWebAppManageAppointment },
      options: { authAs: 'header' },
    });

    const {
      cancelledAppointment: { id },
    } = cancelAppointmentResponse || { cancelledAppointment: { id: 0 } };

    return id ?? null;
  }

  public async decryptSchedulingLinkToRawAcuityLink(): Promise<DecryptedSchedulingLink | null> {
    const hash = new URLSearchParams(window.location.search).get('p');

    if (!hash) {
      return null;
    }

    const response = await this.get<{ success: boolean } & DecryptedSchedulingLinkResponse>({
      url: `/v1/scheduling-links/${hash}`,
    });

    if (!response) {
      return null;
    }

    return response.linkInfo;
  }

  public async getGroupClassesWithAvailability(): Promise<
    AppointmentTypeWithAvailableSlots[] | null
  > {
    const groupClassesResponse = await this.get<{
      appointmentTypes: AppointmentTypeWithAvailableSlots[];
    }>({
      url: '/v3/members/me/appointments/group-classes/availability',
      options: { authAs: 'header' },
    });

    return groupClassesResponse?.appointmentTypes || null;
  }
}

const schedulingClient = new Scheduling(SCHEDULE_API_BASE_URL);

export default schedulingClient;
