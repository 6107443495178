import { FC } from 'react';
import { AppIcon, GetReadyIcon } from '../../../assets/icons';

const CallEnded: FC = () => {
  return (
    <div className='home'>
      <nav className='header'>
        <img src={AppIcon} alt='app-icon' />
      </nav>
      <div className='body'>
        <div className='wrapper'>
          <img src={GetReadyIcon} alt='get-ready-icon' />
          <h2 className='title'>The call has ended</h2>

          <span className='subtitle'>You may now close this window</span>
        </div>
      </div>
    </div>
  );
};

export default CallEnded;
