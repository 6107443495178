import { Stack, Typography } from '@mui/material';
import { DefaultMeetingSession } from 'amazon-chime-sdk-js';
import { FC, useEffect, useRef } from 'react';
import { Participant } from '../../meeting-call.types';

type VideoTrackProps = {
  participant: Participant;
  session: DefaultMeetingSession | null;
  className?: string;
  isScreenShare?: boolean;
};

const VideoTrack: FC<VideoTrackProps> = ({ participant, session, className, isScreenShare }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (!videoRef) {
      return;
    }

    const videoElement = videoRef.current;
    if (!videoElement) {
      return;
    }

    if (!session) {
      return;
    }

    participant.boundElement = videoRef;
    session?.audioVideo.bindVideoElement(participant!.tileState.tileId || 0, videoRef.current!);
    if (!participant.isRemote && audioRef.current) {
      session?.audioVideo.bindAudioElement(audioRef.current!);
    }
  }, []);

  return (
    <Stack className={`chime-video-call-container__video-track ${className}`}>
      <Stack className='chime-video-call-container__participant-name'>
        <label>
          {participant.name ? participant.name : participant.tileState.boundExternalUserId}
        </label>
      </Stack>
      {!participant.isCameraEnabled && !participant.isSharing && (
        <Stack className='chime-video-call-container__no-video-label'>
          <Typography variant='h2'>No video</Typography>
        </Stack>
      )}
      <video ref={videoRef} className='chime-video-call-container__video' />
      <audio id={`meeting-audio-${participant.tileState.boundExternalUserId}`} ref={audioRef} />
    </Stack>
  );
};

export default VideoTrack;
