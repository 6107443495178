import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DoneIcon from '@mui/icons-material/Done';
import SkipTopicIcon from '@mui/icons-material/DoNotDisturb';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Tooltip } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useVideoCall } from '../../../../contexts/videoCall/context';
import membersServiceClient from '../../../../lib/members/client';
import {
  HydratedOutline,
  HydratedTopic,
  OutlineProgressEntry,
  TopicStatus,
} from '../../../../lib/members/types';
import MixpanelClient, { TrackingEventV2 } from '../../../../lib/tracking/mixpanel';
import { StyledTheme } from '../../../../theme';
import { DRAWER_WIDTH, DrawerHeader } from '../RoomContainer';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const Outline: FC<Props> = ({ isOpen, onClose }) => {
  const [hydratedOutline, setHydratedOutline] = useState<HydratedOutline | null>(null);
  const [outlineProgress, setOutlineProgress] = useState<
    (OutlineProgressEntry & { expanded: boolean })[]
  >([]);

  const { videoCallState } = useVideoCall();

  const {
    callInfo: { provider },
  } = videoCallState;

  useEffect(() => {
    (async () => {
      if (!provider?.schedule_id || !provider?.auth_token) {
        return;
      }

      const { schedule_id, auth_token } = provider;
      const params = { appointmentId: schedule_id, providerAuthToken: auth_token };

      const [outline, progress] = await Promise.all([
        membersServiceClient.getMostRecentHydratedOutlineForAppointment(params),
        membersServiceClient.getOutlineProgressIfAny(params),
      ]);

      setHydratedOutline(outline);
      setOutlineProgress((progress || []).map((e) => ({ ...e, expanded: false })));
    })();
  }, [provider]);

  const trackOutlineProgress = (topicSlug: string, topicStatus: TopicStatus): void => {
    const currentProgress = outlineProgress.filter(({ topicSlug: slug }) => slug !== topicSlug);

    const updatedOutlineProgress: (OutlineProgressEntry & { expanded: boolean })[] = [
      ...currentProgress,
      { topicSlug, topicStatus, expanded: false },
    ];

    if (provider?.schedule_id && provider?.auth_token) {
      membersServiceClient.saveOutlineProgress({
        appointmentId: provider.schedule_id,
        progress: updatedOutlineProgress,
        providerAuthToken: provider.auth_token,
      });
    }

    setOutlineProgress(updatedOutlineProgress);
  };

  const renderTopic = (topic: HydratedTopic): ReactNode => {
    const {
      title,
      slug,
      prompt,
      items,
      llmResult: { pvq, hie },
    } = topic;

    const answer = outlineProgress.find(({ topicSlug }) => topicSlug === slug);

    return (
      <Accordion
        key={`outline-topic-${title}`}
        expanded={!answer || !!answer?.expanded}
        onChange={() => {
          const index = outlineProgress.findIndex(({ topicSlug }) => topicSlug === slug);

          if (index !== -1) {
            outlineProgress[index].expanded = !outlineProgress[index].expanded;
            setOutlineProgress([...outlineProgress]);
          }
        }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{title}</AccordionSummary>
        <AccordionDetails>
          {prompt && (
            <>
              <Typography marginBottom={0.5} variant='h5'>
                Provider Prompt:
              </Typography>
              <Typography paragraph variant='h6'>
                {prompt.title}
              </Typography>

              {prompt.suggestions && (
                <ul>
                  {prompt.suggestions.map((s) => (
                    <li key={`outline-prompt-suggestion-${s}`}>
                      <Typography variant='h5'>{s}</Typography>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}

          {items && (
            <ul>
              {items.map((item) => (
                <li key={`outline-rule-${item}`}>
                  <Typography variant='h5'>{item}</Typography>
                </li>
              ))}
            </ul>
          )}

          {pvq && (
            <>
              <Typography marginTop={2} marginBottom={0.5} variant='h5'>
                Relevant information from PVQ:
              </Typography>

              <ul>
                {pvq.map((entry) => (
                  <li key={`outline-pvq-entry-${entry}`}>
                    <Typography variant='h5'>{entry}</Typography>
                  </li>
                ))}
              </ul>
            </>
          )}

          {hie ? (
            hie.map(({ title, summary }) => (
              <div key={`outline-hie-entry-${title}`}>
                <Typography variant='h5'>{title}</Typography>
                <ul>
                  {summary.map((s) => (
                    <li key={`outline-hie-summary-entry-${s}`}>
                      <Typography variant='h5'>{s}</Typography>
                    </li>
                  ))}
                </ul>
              </div>
            ))
          ) : (
            <Typography variant='h5'>No medical records available.</Typography>
          )}
        </AccordionDetails>
        <AccordionActions>
          {[
            {
              title: 'Mark topic as Completed',
              topicStatus: TopicStatus.Completed,
              trackingEvent: TrackingEventV2.VideoCallOutlineCompleteTopic,
              icon: <DoneIcon color={'primary'} fontSize='small' />,
            },
            {
              title: 'Mark topic as Skipped',
              topicStatus: TopicStatus.Skipped,
              trackingEvent: TrackingEventV2.VideoCallOutlineSkipTopic,
              icon: <SkipTopicIcon color={'error'} fontSize='small' />,
            },
          ].map(({ title, topicStatus, trackingEvent, icon }) => (
            <IconButton
              key={`${slug}-${topicStatus}`}
              sx={{
                ...(answer?.topicStatus === topicStatus && {
                  borderColor: StyledTheme.colors.green,
                  borderWidth: 0.5,
                  borderStyle: 'solid',
                }),
              }}
              onClick={() => {
                trackOutlineProgress(slug, topicStatus);
                MixpanelClient.trackEvent({
                  eventName: trackingEvent,
                  properties: { field: title, value: topicStatus },
                });
              }}>
              <Tooltip title={title}>{icon}</Tooltip>
            </IconButton>
          ))}
        </AccordionActions>
      </Accordion>
    );
  };

  const { sections } = hydratedOutline || { sections: [] };

  return (
    <Drawer
      sx={{
        width: isOpen ? DRAWER_WIDTH : 0,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isOpen ? DRAWER_WIDTH : 0,
        },
      }}
      variant='persistent'
      anchor='right'
      open={isOpen}>
      <DrawerHeader>
        <IconButton onClick={onClose}>
          <ChevronRightIcon />
        </IconButton>
      </DrawerHeader>

      <Divider />

      {sections.map(({ title, topics }, index) => (
        <Accordion
          key={`outline-section-${index}`}
          defaultExpanded
          onChange={() => {
            MixpanelClient.trackEvent({
              eventName: TrackingEventV2.VideoCallOutlineClickTopic,
              properties: { field: `Section: ${title}` },
            });
          }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>{title}</AccordionSummary>
          <AccordionDetails>{topics.map((topic) => renderTopic(topic))}</AccordionDetails>
        </Accordion>
      ))}
    </Drawer>
  );
};

export default Outline;
