import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { DateTime } from 'luxon';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import { useFeatures } from '../../contexts/features/context';
import { DATE_TIME_DAY_FORMAT, DATE_TIME_MONTH_FORMAT } from '../../helpers/time';
import { logErrorAndReportToHoneybadger } from '../../lib/errorReporting';
import { Features } from '../../lib/features/client';
import schedulingClient from '../../lib/scheduling/client';
import {
  AcuityAppointment,
  DailyAvailability,
  MonthlyAvailability,
  Specialty,
} from '../../lib/scheduling/types';
import { getAuth } from '../../lib/storage';
import MixpanelClient, { TrackingEvent } from '../../lib/tracking/mixpanel';
import { StyledTheme } from '../../theme';
import CoreLayout from '../Layout/CoreLayout';
import Toast from '../Toast/Toast';
import BookingResult from './BookingResult';
import NoAvailability from './NoAvailability';
import TimeSlotsContainer from './TimeSlotsContainer';
import './scheduling.css';

export type AvailabilityPeriod = 'monthly' | 'daily' | 'none';

type BookingInfo = {
  appointment: AcuityAppointment | null;
  didAttempt: boolean;
  loading: boolean;
};

const Scheduling: FC = () => {
  const [actualDate, setActualDate] = useState<DateTime | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<string | null>(null);
  const [dailyAvailability, setDailyAvailability] = useState<DailyAvailability | null>(null);
  const [monthlyAvailability, setMonthlyAvailability] = useState<MonthlyAvailability | null>(null);
  const [bookingInfo, setBookingInfo] = useState<BookingInfo>({
    appointment: null,
    didAttempt: false,
    loading: false,
  });
  const [pullingAvailability, setPullingAvailability] = useState<{
    ongoing: boolean;
    period: AvailabilityPeriod;
  }>({ ongoing: false, period: 'none' });

  const { featuresState } = useFeatures();

  const [searchParams] = useSearchParams();

  const currentMonthRef = useRef<string | null>(null);
  const urlParamsRef = useRef<{
    appointmentTypeId: number | null;
    specialty: Specialty | null;
  } | null>(null);

  const fetchDailyAvailability = useCallback(async (rawDate: string): Promise<void> => {
    setActualDate(DateTime.fromISO(rawDate));

    setDailyAvailability(null);
    setSelectedSlot(null);

    setPullingAvailability({ ongoing: true, period: 'daily' });

    const { appointmentTypeId, specialty } = urlParamsRef.current!;

    const dailyAvailability = await schedulingClient.getDailyAvailability({
      appointmentTypeId,
      specialty,
      date: DateTime.fromISO(rawDate).toFormat(DATE_TIME_DAY_FORMAT),
    });

    setDailyAvailability(dailyAvailability);

    setPullingAvailability((prev) => ({ ...prev, ongoing: false }));
  }, []);

  const fetchMonthlyAvailability = useCallback(
    async (rawMonth: string): Promise<void> => {
      // Let's store the latest month we're fetching availability for.
      // If there are 2 requests, this will keep the value of the most recent request.
      currentMonthRef.current = rawMonth;

      setDailyAvailability(null);
      setSelectedSlot(null);

      setPullingAvailability({ ongoing: true, period: 'monthly' });

      const { appointmentTypeId, specialty } = urlParamsRef.current!;

      // The following call is async, so there's a chance that the user has already changed the month by the time we end.
      const monthlyAvailability = await schedulingClient.getMonthlyAvailability({
        appointmentTypeId,
        specialty,
        month: DateTime.fromISO(rawMonth).toFormat(DATE_TIME_MONTH_FORMAT),
      });

      // If the month has changed, we don't want to update the state because there's a newer request in progress for a different month.
      if (currentMonthRef.current !== rawMonth) {
        return;
      }

      setMonthlyAvailability(monthlyAvailability);
      setPullingAvailability((prev) => ({ ...prev, ongoing: false }));

      // Always fetch daily availability for the first slot available
      if (monthlyAvailability && monthlyAvailability.dates.length > 0) {
        if (featuresState[Features.MembersWebAppHideForbiddenDates].enabled) {
          const firstAllowedDate = monthlyAvailability.dates.filter(({ allowed }) => allowed)[0];

          if (firstAllowedDate) {
            fetchDailyAvailability(firstAllowedDate.date);
          }
        } else {
          fetchDailyAvailability(monthlyAvailability.dates[0].date);
        }
      }
    },
    [fetchDailyAvailability, featuresState]
  );

  useEffect(() => {
    if (!featuresState.loaded) {
      return;
    }

    const auth = getAuth();

    if (!auth) {
      throw new Error('No auth available, cannot pull availability');
    }

    MixpanelClient.trackEvent({
      eventName: TrackingEvent.Visit,
      properties: { field: 'Scheduling Page' },
    });

    const appointmentTypeId = schedulingClient.getAppointmentTypeIdParamFromURL();
    const specialty = schedulingClient.getSpecialtyParamFromURL();

    if (!appointmentTypeId && !specialty) {
      throw new Error('Invalid params. Either appointment type ID or specialty is required');
    }

    urlParamsRef.current = { appointmentTypeId, specialty };

    fetchMonthlyAvailability(DateTime.local().toString());
  }, [searchParams, featuresState.loaded, fetchMonthlyAvailability]);

  const handleMonthChange = (month: string): void => {
    MixpanelClient.trackEvent({
      eventName: TrackingEvent.Click,
      properties: { field: 'Month Change' },
    });

    fetchMonthlyAvailability(month);
  };

  const handleDayChange = async (value: string | null): Promise<void> => {
    if (!value) {
      return;
    }
    MixpanelClient.trackEvent({
      eventName: TrackingEvent.Click,
      properties: { field: 'Day Change' },
    });

    fetchDailyAvailability(value);
  };

  const handleShouldDisableDate = (day: string): boolean => {
    if (!monthlyAvailability) {
      return false;
    }

    const formattedDay = DateTime.fromISO(day).toFormat(DATE_TIME_DAY_FORMAT);

    const { dates } = monthlyAvailability;
    const dateSlot = dates.find(({ date }) => date === formattedDay);

    if (featuresState[Features.MembersWebAppHideForbiddenDates]) {
      return !dateSlot?.allowed;
    }

    return !dateSlot;
  };

  const handleBookAppointment = async (): Promise<void> => {
    MixpanelClient.trackEvent({
      eventName: TrackingEvent.Click,
      properties: { field: 'Book Appointment' },
    });

    setBookingInfo({ ...bookingInfo, loading: true });

    const {
      appointmentType: { id },
      provider: {
        calendarId,
        id: providerId,
        firstName: providerFirstName,
        lastName: providerLastName,
      },
    } = monthlyAvailability!;

    let appointment;
    try {
      appointment = await schedulingClient.createAppointment({
        appointmentTypeId: id,
        dateTime: selectedSlot!,
        calendarId,
      });
    } catch (error) {
      Toast.error('Could not schedule the appointment');
      logErrorAndReportToHoneybadger({ error });
    }

    setBookingInfo({
      appointment: appointment
        ? {
            ...appointment,
            provider: { providerId, providerFirstName, providerLastName },
          }
        : null,
      didAttempt: true,
      loading: false,
    });
  };

  if (!featuresState.loaded) {
    return (
      <CoreLayout>
        <CircularProgress size={20} />
      </CoreLayout>
    );
  }

  return (
    <CoreLayout>
      <Helmet>
        <title>Book your appointment - Enara</title>
      </Helmet>
      {bookingInfo.didAttempt ? (
        <BookingResult
          appointment={bookingInfo.appointment}
          appointmentType={monthlyAvailability?.appointmentType}
        />
      ) : (
        <>
          <Box className='main-schedule-container'>
            {monthlyAvailability && (
              <div style={{ margin: '0 auto', width: '100%' }}>
                <Typography variant='h5' marginTop={5} marginBottom={5}>
                  {monthlyAvailability.appointmentType.officialName} with
                  {` ${monthlyAvailability.provider.firstName} ${monthlyAvailability.provider.lastName}`}
                </Typography>
              </div>
            )}

            <Typography variant='h3'>Select the date and time:</Typography>
            <div className='scheduling-container'>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DateCalendar
                  className='date-calendar'
                  disablePast
                  openTo={'day'}
                  views={['day', 'month']}
                  value={actualDate as unknown as string}
                  onMonthChange={handleMonthChange}
                  onChange={handleDayChange}
                  shouldDisableDate={handleShouldDisableDate}
                />
              </LocalizationProvider>

              <TimeSlotsContainer
                actualDate={actualDate}
                selectedSlot={selectedSlot}
                dailyAvailability={dailyAvailability}
                onSelectSlot={setSelectedSlot}
              />

              {pullingAvailability.ongoing ? (
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  spacing={2}>
                  <Typography variant='h3'>
                    Fetching {pullingAvailability.period} availability
                  </Typography>
                  <CircularProgress size={20} />
                </Stack>
              ) : (
                <NoAvailability
                  period={pullingAvailability.period}
                  monthlyAvailability={monthlyAvailability}
                  dailyAvailability={dailyAvailability}
                />
              )}
            </div>
          </Box>

          <div className='bottom-button-container'>
            <Button
              variant={'contained'}
              className='appointment-btn'
              disabled={bookingInfo.loading || !selectedSlot}
              onClick={handleBookAppointment}>
              Confirm Appointment{' '}
              {bookingInfo.loading && (
                <CircularProgress size={20} style={{ color: StyledTheme.colors.lightGray }} />
              )}
            </Button>
          </div>
        </>
      )}
    </CoreLayout>
  );
};

export default Scheduling;
