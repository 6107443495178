import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router';
import schedulingClient from '../../lib/scheduling/client';
import { AppointmentTypeWithAvailableSlots } from '../../lib/scheduling/types';
import MixpanelClient, { TrackingEventV2 } from '../../lib/tracking/mixpanel';
import userClient from '../../lib/user/client';
import Authenticating from '../Common/Authenticating/Authenticating';
import Prompt from '../Common/Prompt/Prompt';
import CoreLayout from '../Layout/CoreLayout';
import GroupClassCard from './GroupClassCard';
import './group-class-card.css';

const GroupClasses: FC = () => {
  const [groupClassAvailability, setGroupClassAvailability] = useState<
    AppointmentTypeWithAvailableSlots[]
  >([]);

  const [fetchingStatus, setFetchingStatus] = useState<'authenticating' | 'fetching' | 'done'>(
    'authenticating'
  );

  const { token, ...searchParams } = useParams<{ token: string; src: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      return;
    }

    (async () => {
      if (!(await userClient.getProfileWithToken(token))) {
        return navigate(`/error`);
      }

      MixpanelClient.trackEvent({
        eventName: TrackingEventV2.GroupClassListClasses,
        properties: { ...searchParams, source: searchParams.src || '' },
      });

      setFetchingStatus('fetching');

      const groupClassAvailability = await schedulingClient.getGroupClassesWithAvailability();

      if (!groupClassAvailability) {
        return navigate(`/error`);
      }

      setFetchingStatus('done');
      setGroupClassAvailability(groupClassAvailability);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, navigate]);

  return (
    <>
      <Helmet>
        <title>Book your group class - Enara</title>
      </Helmet>

      {fetchingStatus === 'authenticating' ? (
        <Authenticating />
      ) : fetchingStatus === 'fetching' ? (
        <Prompt
          title={'Group class availability'}
          content={'We are verifying and downloading availability for group classes.'}
        />
      ) : (
        <CoreLayout logo={false}>
          <div className='gc-container'>
            {groupClassAvailability.map((appointmentTypeWithSlots, i) => (
              <GroupClassCard
                key={`group-class-${i}`}
                index={i}
                appointmentTypeWithSlots={appointmentTypeWithSlots}
                onRegister={(appointment) => {
                  navigate(`/group-classes/${appointment.id}/detail`, { state: appointment });
                }}
              />
            ))}
          </div>
        </CoreLayout>
      )}
    </>
  );
};

export default GroupClasses;
