import { Dispatch } from 'react';
import { Profile } from '../../lib/user/types';

export type AuthContextValue = {
  authState: AuthState;
  dispatchAuth: Dispatch<AuthActionTypes>;
};

export type AuthState = {
  user: Profile | null;
};

export enum AuthActions {
  SetUser = '@enara/types/set-user',
}

export type SetUserType = {
  type: AuthActions.SetUser;
  payload: Profile;
};

export type AuthActionTypes = SetUserType;
