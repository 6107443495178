import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { getAuth } from '../../lib/storage';

const MainMenuPage: FC = () => {
  useEffect(() => {
    if (!getAuth()) {
      redirect('/login');
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>Menu - Enara</title>
      </Helmet>
      <h1 style={{ display: window.location?.hostname === 'localhost' ? undefined : 'none' }}>
        <Link to='/'>Menu</Link>
      </h1>
      <ul>
        <li>
          <Link to='/appointments'>My appointments</Link>
        </li>
        <li>Group classes</li>
      </ul>
    </div>
  );
};

export default MainMenuPage;
