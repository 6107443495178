import { Box, Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { FC, useEffect, useState } from 'react';
import { CloseIcon } from '../../../../assets/icons/svg';
import { useVideoCall } from '../../../../contexts/videoCall/context';
import videoServiceClient from '../../../../lib/video/client';
import { DRAWER_WIDTH, DrawerHeader } from '../RoomContainer';
import './recording-dialog.css';

type Props = {
  isRecording: boolean;
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

const RecordingDialog: FC<Props> = ({ isRecording, isOpen, onClose, onConfirm }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isRecordingRequested, setIsRecordingRequested] = useState(false);
  const { videoCallState } = useVideoCall();

  // TODO: Replace 'callInfo' with 'meetingInfo' to improve data accuracy and consistency.
  const {
    callInfo: { provider, room },
  } = videoCallState;

  useEffect(() => {
    (async () => {
      if (!provider?.schedule_id || !provider?.auth_token) {
        return;
      }

      setIsLoaded(true);
    })();
  }, [provider]);

  async function handleOnConfirm() {
    if (!isLoaded) {
      return;
    }

    setIsConfirming(true);
    const result = await videoServiceClient.startMeetingCapture(room, provider?.auth_token!);
    setIsConfirming(false);

    if (result) {
      // On success -> We close the section
      onConfirm();
      onClose();
    }
  }

  return (
    <Drawer
      className='recording-dialog-container'
      sx={{
        width: isOpen ? DRAWER_WIDTH : 0,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isOpen ? DRAWER_WIDTH : 0,
        },
      }}
      variant='persistent'
      anchor='right'
      open={isOpen}>
      <DrawerHeader className='recording-dialog-header'>
        <Typography variant='h5'>Recording</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>

      <Divider />

      <Box className='recording-dialog-content'>
        <Typography marginBottom={1} variant='h4'>
          {isRecording ? 'Recording in progress' : 'Record your video call'}
        </Typography>

        <Typography paragraph variant='h6'>
          {isRecording
            ? 'Recording is already in progress. It will be ended automatically after your patient leaves and this call is closed.'
            : 'Before continue, please warn your patient about this call is going to be recorded with the following message:'}
        </Typography>

        {!isRecording && (
          <Typography paragraph variant='body2'>
            <i>
              "Today I will be taking notes using a HIPAA compliant digital recording system so I
              can better focus on our discussion. Do I have your permission to proceed?"
            </i>
          </Typography>
        )}

        <FormGroup className='dialog-form'>
          {!isRecording && (
            <FormControlLabel
              required
              control={<Checkbox onChange={(_, checked) => setIsRecordingRequested(checked)} />}
              label='I have requested HIPAA consents to proceed'
            />
          )}

          <Button
            onClick={isRecording ? onClose : handleOnConfirm}
            variant='contained'
            color='secondary'
            disabled={!isLoaded || !isRecordingRequested || isConfirming}>
            {isRecording ? 'Continue' : 'Start recording'}
          </Button>
        </FormGroup>
      </Box>
    </Drawer>
  );
};

export default RecordingDialog;
