import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/auth/context';
import { AuthActions } from '../../contexts/auth/types';
import { logErrorAndReportToHoneybadger } from '../../lib/errorReporting';
import userClient from '../../lib/user/client';
import { Profile } from '../../lib/user/types';

function isAuthorized(userProfile: Profile, memberId: number | null) {
  if (userProfile?.isProvider) {
    return true;
  }

  return userProfile?.id === memberId;
}

const PrivateRoute: FC = () => {
  const [searchParams] = useSearchParams();
  const { authState, dispatchAuth } = useAuth();

  const [authLoading, setAuthLoading] = useState<boolean>(true);

  const memberId = searchParams.get('memberId');
  const authorized = useMemo(
    () => authState.user && isAuthorized(authState.user, Number(memberId)),
    [authState.user, memberId]
  );

  const login = useCallback(async () => {
    const authToken = searchParams.get('authToken');
    if (authToken) {
      try {
        /**
         * For now, routes are private for yourself (member) or all providers
         */
        const userProfile = await userClient.getProfileWithToken(authToken);
        if (userProfile) {
          dispatchAuth({ type: AuthActions.SetUser, payload: userProfile });
        }
      } catch (error) {
        logErrorAndReportToHoneybadger({ error });
      }
    }
    setAuthLoading(false);
  }, [searchParams, dispatchAuth]);

  useEffect(() => {
    login();
  }, [login]);

  if (authLoading) {
    return <div>Loading...</div>;
  }

  if (!authorized) {
    return <Navigate to='/' />;
  }

  return <Outlet />;
};

export default PrivateRoute;
