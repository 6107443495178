import { logErrorAndReportToHoneybadger } from './errorReporting';
import { AcuityAppointmentTag } from './scheduling/types';
import { Auth } from './user/types';

export enum StorageKeys {
  AuthKey = 'member-auth',
  TelemedicineSchedulingId = 'telemedicine-scheduling-id',
  AppointmentTag = 'appointment-tag',
}

export const storeAuth = (auth: Auth): void => {
  localStorage.setItem(StorageKeys.AuthKey, JSON.stringify(auth));
};

export const getAuth = (): Auth | null => {
  const auth = localStorage.getItem(StorageKeys.AuthKey);

  return auth ? (JSON.parse(auth) as Auth) : null;
};

export const trackAppointmentTagForCurrentSession = (tag: AcuityAppointmentTag): void => {
  sessionStorage.setItem(StorageKeys.AppointmentTag, tag);
};

export const getAppointmentTagForCurrentSession = (): AcuityAppointmentTag => {
  return sessionStorage.getItem(StorageKeys.AppointmentTag) as AcuityAppointmentTag;
};

export const getItem = (key: StorageKeys): string | number | null => {
  const value = localStorage.getItem(key);

  try {
    if (value) {
      return JSON.parse(value);
    }

    return null;
  } catch (error) {
    logErrorAndReportToHoneybadger({ error, isReportable: false });

    return value;
  }
};

export const setItem = (key: StorageKeys, value: string | number): void => {
  return localStorage.setItem(key, JSON.stringify(value));
};
