import { Dispatch } from 'react';
import { AllFeatures } from '../../lib/features/client';

export type FeaturesContextValue = {
  featuresState: FeaturesState;
  dispatchFeatures: Dispatch<FeaturesActionTypes>;
};

export type FeaturesState = AllFeatures & { loaded: boolean };

export enum FeaturesActions {
  SetAll = '@enara/types/set-all-features',
}

export type SetAllFeaturesType = {
  type: FeaturesActions.SetAll;
  payload: AllFeatures | null;
};

export type FeaturesActionTypes = SetAllFeaturesType;
