import { MeetingInformation } from '../../Components/ChimeVideoCall/meeting-call.types';
import { SCHEDULE_API_BASE_URL } from '../environment';
import { logErrorAndReportToHoneybadger } from '../errorReporting';
import HttpRequest from '../request';
import { VideoRoomResponse } from './types';

class VideoServiceClient extends HttpRequest {
  public async fetchVideoRoomWithToken({
    token,
    memberId,
  }: {
    token: string;
    memberId?: string | null;
  }): Promise<VideoRoomResponse | null> {
    const queryParameters = { token, ...(memberId && { memberId }) };

    const queryParametersAsString = Object.entries(queryParameters).reduce(
      (acc, [key, value]) => `${acc}&${key}=${value}`,
      ''
    );
    try {
      const response = await this.axiosInstance.get<{ data: VideoRoomResponse }>(
        `/video-call/v1/token?${queryParametersAsString}`
      );

      return response.data.data;
    } catch (error) {
      logErrorAndReportToHoneybadger({ error });

      throw error;
    }
  }

  public async chimeFetchVideoRoomWithToken({
    token,
  }: {
    token: string;
  }): Promise<MeetingInformation | null> {
    try {
      const response = await this.axiosInstance.get(
        `/video-call/v2/meeting/join?token=${encodeURIComponent(token)}`
      );

      return response.data;
    } catch (error) {
      logErrorAndReportToHoneybadger({ error });

      throw error;
    }
  }

  public async closeMeeting(meetingId: string, enaraAuthToken: string) {
    try {
      await this.axiosInstance.delete(`/video-call/v2/meeting/${meetingId}`, {
        headers: {
          Authorization: `Bearer ${enaraAuthToken}`,
        },
      });
    } catch (error) {
      logErrorAndReportToHoneybadger({ error });

      throw error;
    }
  }

  public async startMeetingCapture(meetingId: string, enaraAuthToken: string) {
    try {
      await this.axiosInstance.post(`/video-call/v2/meeting/${meetingId}/capture`, null, {
        headers: {
          Authorization: `Bearer ${enaraAuthToken}`,
        },
      });

      return true;
    } catch (error) {
      logErrorAndReportToHoneybadger({ error });

      return false;
    }
  }
}

const videoServiceClient = new VideoServiceClient(SCHEDULE_API_BASE_URL);

export default videoServiceClient;
