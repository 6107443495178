import { MEMBERS_BASE_URL } from '../environment';
import HttpRequest from '../request';
import { HydratedOutline, OutlineProgressEntry } from './types';

class MembersServiceClient extends HttpRequest {
  public async saveOutlineProgress({
    appointmentId,
    progress,
    providerAuthToken,
  }: {
    appointmentId: number;
    progress: OutlineProgressEntry[];
    providerAuthToken: string;
  }): Promise<void> {
    await this.put<void>({
      url: `/v1/appointments/${appointmentId}/outline`,
      payload: progress,
      options: { authAs: 'header', token: providerAuthToken },
    });
  }

  public async getOutlineProgressIfAny({
    appointmentId,
    providerAuthToken,
  }: {
    appointmentId: number;
    providerAuthToken: string;
  }): Promise<OutlineProgressEntry[] | null> {
    const response = await this.get<{ statuses: { [index: number]: OutlineProgressEntry } }>({
      url: `/v1/appointments/${appointmentId}/outline`,
      options: { authAs: 'header', token: providerAuthToken },
    });

    if (response?.statuses) {
      const { statuses } = response;

      return Object.values(statuses).reduce(
        (progress, curr) => [...progress, curr],
        [] as OutlineProgressEntry[]
      );
    }

    return null;
  }

  public async getMostRecentHydratedOutlineForAppointment({
    appointmentId,
    providerAuthToken,
  }: {
    appointmentId: number;
    providerAuthToken: string;
  }): Promise<HydratedOutline | null> {
    const response = await this.get<{ hydratedOutline: HydratedOutline }>({
      url: `/v1/appointments/${appointmentId}/hydrated-outline`,
      options: { authAs: 'header', token: providerAuthToken },
    });

    return response?.hydratedOutline || null;
  }
}

const membersServiceClient = new MembersServiceClient(MEMBERS_BASE_URL);

export default membersServiceClient;
