/* eslint-disable jsx-a11y/heading-has-content */
import { Card } from '@mui/material';
import Chart from 'chart.js';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import { kgToPounds } from '../../helpers/health';
import { DATE_TIME_DAY_FORMAT } from '../../helpers/time';
import { logErrorAndReportToHoneybadger } from '../../lib/errorReporting';
import healthRecordsServiceClient from '../../lib/health-records/client';
import { BodyCompositionRecord } from '../../lib/health-records/types';
import { getAuth } from '../../lib/storage';
import './body-composition.css';
import CompareMetric from './components/CompareMetric/CompareMetric';
import ErrorPage from './components/ErrorPage/ErrorPage';

function parseMassInLbs(massInLbs: number) {
  return `${massInLbs.toFixed(2)} lbs`;
}

function getComparisonValueInLbs(futureValueInLbs?: number, pastValueInLbs?: number) {
  if (futureValueInLbs === undefined || pastValueInLbs === undefined) {
    return 0;
  }

  return futureValueInLbs - pastValueInLbs;
}

const primaryEnaraColor = '#1A667A';
const secondaryEnaraColor = '#C3E5E3';
const limitEnaraColor = '#FF3B30';

const BodyComposition = () => {
  const [searchParams] = useSearchParams();

  const [error, setError] = useState<{ message: string } | null>(null);
  const [bodyCompositionRecords, setBodyCompositionRecords] = useState<
    BodyCompositionRecord['recordedContent'][]
  >([]);
  const [currentBodyCompositionMetricsInLbs, setCurrentBodyCompositionMetricsInLbs] = useState({
    metrics: {
      bodyFatMass: {
        comparisonPreviousValue: 0,
        comparisonSinceStart: 0,
        value: 0,
      },
      skeletalMuscleMass: {
        comparisonPreviousValue: 0,
        comparisonSinceStart: 0,
        value: 0,
      },
      weight: {
        comparisonPreviousValue: 0,
        comparisonSinceStart: 0,
        value: 0,
      },
    },
    loading: true,
  });

  const buildBodyFatVsSkeletalMuscleMassChart = useCallback(() => {
    const smmValue = currentBodyCompositionMetricsInLbs.metrics.skeletalMuscleMass.value;
    const bfmValue = currentBodyCompositionMetricsInLbs.metrics.bodyFatMass.value;
    const bfsmChartElement = document.getElementById('myPieChart') as HTMLCanvasElement;
    return new Chart(bfsmChartElement, {
      type: 'pie',
      data: {
        datasets: [
          {
            data: [Number.parseInt(`${smmValue}`), Number.parseInt(`${bfmValue}`)],
            backgroundColor: [primaryEnaraColor, secondaryEnaraColor],
          },
        ],
        labels: ['Skeletal Muscle Mass', 'Body Fat Mass'],
      },
    });
  }, [currentBodyCompositionMetricsInLbs]);

  const buildSkeletalMuscleMassTrendChart = useCallback(
    (labels: string[]) => {
      const smmtValues = bodyCompositionRecords
        .map(({ SMM }) => SMM)
        .map((kg: string) => kgToPounds(Number(kg)))
        .map((value) => Number(value.toFixed(2)));
      const smmtChartElement = document.getElementById('lean-mass-chart') as HTMLCanvasElement;
      return new Chart(smmtChartElement, {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: 'Skeletal Muscle Mass',
              data: smmtValues,
              borderColor: primaryEnaraColor,
              backgroundColor: primaryEnaraColor,
              fill: false,
              pointBackgroundColor: primaryEnaraColor,
            },
          ],
        },
        options: {
          responsive: true,
          title: { display: true, text: 'Skeletal Muscle Mass Trend' },
          tooltips: { mode: 'index', intersect: false },
          hover: { mode: 'nearest', intersect: true },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: { display: true },
                gridLines: { display: false },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: { display: true, labelString: 'Lbs.' },
                ticks: {
                  min: Math.min(...smmtValues) - 1,
                  max: Math.max(...smmtValues) + 1,
                },
                gridLines: { display: false },
              },
            ],
          },
        },
      });
    },
    [bodyCompositionRecords]
  );

  const buildBodyFatMassTrendChart = useCallback(
    (labels: string[]) => {
      const bfmtValues = bodyCompositionRecords
        .map(({ BFM }) => BFM)
        .map((kg: string) => kgToPounds(Number(kg)))
        .map((value) => Number(value.toFixed(2)));
      const bfmtChartElement = document.getElementById('bf-trend-chart1') as HTMLCanvasElement;
      return new Chart(bfmtChartElement, {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: 'Body Fat Mass',
              data: bfmtValues,
              borderColor: secondaryEnaraColor,
              backgroundColor: secondaryEnaraColor,
              fill: false,
              pointBackgroundColor: secondaryEnaraColor,
            },
          ],
        },
        options: {
          responsive: true,
          title: { display: true, text: 'Body Fat Mass Trend' },
          tooltips: { mode: 'index', intersect: false },
          hover: { mode: 'nearest', intersect: true },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: { display: true },
                gridLines: { display: false },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: { display: true, labelString: 'Lbs.' },
                ticks: {
                  min: Math.min(...bfmtValues) - 1,
                  max: Math.max(...bfmtValues) + 1,
                },
                gridLines: { display: false },
              },
            ],
          },
        },
      });
    },
    [bodyCompositionRecords]
  );

  const buildWeightTrendChart = useCallback(
    (labels: string[]) => {
      const wtValues = bodyCompositionRecords
        .map(({ WT }) => WT)
        .map((kg: string) => kgToPounds(Number(kg)))
        .map((value) => Number(Number(value.toFixed(2))));
      const wtChartElement = document.getElementById('weight-chart') as HTMLCanvasElement;
      return new Chart(wtChartElement, {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: 'Weight',
              data: wtValues,
              borderColor: primaryEnaraColor,
              backgroundColor: primaryEnaraColor,
              fill: false,
              pointBackgroundColor: primaryEnaraColor,
            },
          ],
        },
        options: {
          responsive: true,
          title: { display: true, text: 'Weight Trend' },
          tooltips: { mode: 'index', intersect: false },
          hover: { mode: 'nearest', intersect: true },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: { display: true },
                gridLines: { display: false },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: { display: true, labelString: 'Lbs.' },
                ticks: {
                  min: Math.min(...wtValues) - 1,
                  max: Math.max(...wtValues) + 1,
                },
                gridLines: { display: false },
              },
            ],
          },
        },
      });
    },
    [bodyCompositionRecords]
  );

  const buildPercentageBodyFatChart = useCallback(
    (labels: string[]) => {
      const pbfValues = bodyCompositionRecords.map(({ PBF }) => Number(PBF));
      const pbfChartElement = document.getElementById('pbf-chart') as HTMLCanvasElement;
      return new Chart(pbfChartElement, {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: 'Percentage Body Fat',
              data: pbfValues,
              borderColor: primaryEnaraColor,
              backgroundColor: primaryEnaraColor,
              fill: false,
              pointBackgroundColor: primaryEnaraColor,
            },
            {
              label: 'Healthy Low Limit',
              data: bodyCompositionRecords.map(({ GENDER }) => (GENDER === 'M' ? 10 : 18)),
              borderColor: limitEnaraColor,
              backgroundColor: 'white',
              fill: false,
              pointBackgroundColor: limitEnaraColor,
              pointRadius: 0,
              borderDash: [10, 15],
            },
            {
              label: 'Healthy High Limit',
              data: bodyCompositionRecords.map(({ GENDER }) => (GENDER === 'M' ? 20 : 28)),
              borderColor: limitEnaraColor,
              backgroundColor: 'white',
              fill: false,
              pointBackgroundColor: limitEnaraColor,
              pointRadius: 0,
              borderDash: [10, 15],
            },
          ],
        },
        options: {
          responsive: true,
          title: { display: true, text: 'Percentage Body Fat' },
          tooltips: { mode: 'index', intersect: false },
          hover: { mode: 'nearest', intersect: true },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: { display: true },
                gridLines: { display: false },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: { display: true, labelString: '%' },
                ticks: {
                  min:
                    bodyCompositionRecords[0].GENDER === 'M'
                      ? Math.min(...pbfValues, 10) - 1
                      : Math.min(...pbfValues, 18) - 1,
                  max:
                    bodyCompositionRecords[0].GENDER === 'M'
                      ? Math.max(...pbfValues, 20) + 1
                      : Math.max(...pbfValues, 18) + 1,
                },
                gridLines: { display: false },
              },
            ],
          },
        },
      });
    },
    [bodyCompositionRecords]
  );

  const buildSkeletalMuscleMassChart = useCallback(
    (labels: string[]) => {
      const smmValues = bodyCompositionRecords
        .map(({ SMM }) => SMM)
        .map((kg: string) => kgToPounds(Number(kg)))
        .map((value) => Number(value.toFixed(2)));
      const bfmValues = bodyCompositionRecords
        .map(({ BFM }) => BFM)
        .map((kg: string) => kgToPounds(Number(kg)))
        .map((value) => Number(value.toFixed(2)));
      const smmChartElement = document.getElementById('smm-fm-chart1') as HTMLCanvasElement;
      return new Chart(smmChartElement, {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: 'Skeletal Muscle Mass',
              data: smmValues,
              borderColor: primaryEnaraColor,
              backgroundColor: primaryEnaraColor,
              fill: false,
              pointBackgroundColor: primaryEnaraColor,
            },
            {
              label: 'Body Fat Mass',
              data: bfmValues,
              borderColor: secondaryEnaraColor,
              backgroundColor: secondaryEnaraColor,
              fill: false,
              pointBackgroundColor: secondaryEnaraColor,
            },
          ],
        },
        options: {
          title: { display: true, text: 'Skeletal Muscle and Body Fat Mass Trend' },
          tooltips: { mode: 'index', intersect: false },
          hover: { mode: 'nearest', intersect: true },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: { display: true },
                gridLines: { display: false },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: { display: true, labelString: 'Lbs.' },
                ticks: {
                  min: Math.min(...smmValues, ...bfmValues) - 10,
                  max: Math.max(...smmValues, ...bfmValues) + 10,
                },
                gridLines: { display: false },
              },
            ],
          },
        },
      });
    },
    [bodyCompositionRecords]
  );

  const buildRightArmMuscleMassChart = useCallback(
    (labels: string[]) => {
      const ramValues = bodyCompositionRecords
        .map(({ LRA }) => LRA)
        .map((kg: string) => kgToPounds(Number(kg)))
        .map((value) => Number(value.toFixed(2)));
      const rafmValues = bodyCompositionRecords
        .map(({ FRA }) => FRA)
        .map((kg: string) => kgToPounds(Number(kg)))
        .map((value) => Number(value.toFixed(2)));
      const ramChartElement = document.getElementById('right-arm-chart') as HTMLCanvasElement;
      return new Chart(ramChartElement, {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: 'Right Arm Muscle Mass',
              data: ramValues,
              borderColor: primaryEnaraColor,
              backgroundColor: primaryEnaraColor,
              fill: false,
              pointBackgroundColor: primaryEnaraColor,
            },
            {
              label: 'Right Arm Fat Mass',
              data: rafmValues,
              borderColor: secondaryEnaraColor,
              backgroundColor: secondaryEnaraColor,
              fill: false,
              pointBackgroundColor: secondaryEnaraColor,
            },
          ],
        },
        options: {
          responsive: true,
          title: { display: true, text: 'Right Arm Muscles and Fat Mass' },
          tooltips: { mode: 'index', intersect: false },
          hover: { mode: 'nearest', intersect: true },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: { display: true },
                gridLines: { display: false },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: { display: true, labelString: 'Lbs.' },
                ticks: {
                  min: Math.min(...ramValues, ...rafmValues) - 2,
                  max: Math.max(...ramValues, ...rafmValues) + 2,
                },
                gridLines: { display: false },
              },
            ],
          },
        },
      });
    },
    [bodyCompositionRecords]
  );

  const buildLeftArmMuscleMassChart = useCallback(
    (labels: string[]) => {
      const lamValues = bodyCompositionRecords
        .map(({ LLA }) => LLA)
        .map((kg: string) => kgToPounds(Number(kg)))
        .map((value) => Number(value.toFixed(2)));
      const lafmValues = bodyCompositionRecords
        .map(({ FLA }) => FLA)
        .map((kg: string) => kgToPounds(Number(kg)))
        .map((value) => Number(value.toFixed(2)));
      const lamChartElement = document.getElementById('left-arm-chart') as HTMLCanvasElement;
      return new Chart(lamChartElement, {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: 'Left Arm Muscle Mass',
              data: lamValues,
              borderColor: primaryEnaraColor,
              backgroundColor: primaryEnaraColor,
              fill: false,
              pointBackgroundColor: primaryEnaraColor,
            },
            {
              label: 'Left Arm Fat Mass',
              data: lafmValues,
              borderColor: secondaryEnaraColor,
              backgroundColor: secondaryEnaraColor,
              fill: false,
              pointBackgroundColor: secondaryEnaraColor,
            },
          ],
        },
        options: {
          responsive: true,
          title: { display: true, text: 'Left Arm Muscles and Fat Mass' },
          tooltips: { mode: 'index', intersect: false },
          hover: { mode: 'nearest', intersect: true },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: { display: true },
                gridLines: { display: false },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: { display: true, labelString: 'Lbs.' },
                ticks: {
                  min: Math.min(...lamValues, ...lafmValues) - 2,
                  max: Math.max(...lamValues, ...lafmValues) + 2,
                },
                gridLines: { display: false },
              },
            ],
          },
        },
      });
    },
    [bodyCompositionRecords]
  );

  const buildRightLegMuscleMassChart = useCallback(
    (labels: string[]) => {
      const rlmValues = bodyCompositionRecords
        .map(({ LRL }) => LRL)
        .map((kg: string) => kgToPounds(Number(kg)))
        .map((value) => Number(value.toFixed(2)));
      const rlfmValues = bodyCompositionRecords
        .map(({ FRL }) => FRL)
        .map((kg: string) => kgToPounds(Number(kg)))
        .map((value) => Number(value.toFixed(2)));
      const rlmChartElement = document.getElementById('right-leg-chart') as HTMLCanvasElement;
      return new Chart(rlmChartElement, {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: 'Right Leg Muscle Mass',
              data: rlmValues,
              borderColor: primaryEnaraColor,
              backgroundColor: primaryEnaraColor,
              fill: false,
              pointBackgroundColor: primaryEnaraColor,
            },
            {
              label: 'Right Leg Fat Mass',
              data: rlfmValues,
              borderColor: secondaryEnaraColor,
              backgroundColor: secondaryEnaraColor,
              fill: false,
              pointBackgroundColor: secondaryEnaraColor,
            },
          ],
        },
        options: {
          responsive: true,
          title: { display: true, text: 'Right Leg Muscles and Fat Mass' },
          tooltips: { mode: 'index', intersect: false },
          hover: { mode: 'nearest', intersect: true },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: { display: true },
                gridLines: { display: false },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: { display: true, labelString: 'Lbs.' },
                ticks: {
                  min: Math.min(...rlmValues, ...rlfmValues) - 2,
                  max: Math.max(...rlmValues, ...rlfmValues) + 2,
                },
                gridLines: { display: false },
              },
            ],
          },
        },
      });
    },
    [bodyCompositionRecords]
  );

  const buildLeftLegMuscleMassChart = useCallback(
    (labels: string[]) => {
      const llmValues = bodyCompositionRecords
        .map(({ LLL }) => LLL)
        .map((kg: string) => kgToPounds(Number(kg)))
        .map((value) => Number(value.toFixed(2)));
      const llfmValues = bodyCompositionRecords
        .map(({ FLL }) => FLL)
        .map((kg: string) => kgToPounds(Number(kg)))
        .map((value) => Number(value.toFixed(2)));
      const llmChartElement = document.getElementById('left-leg-chart') as HTMLCanvasElement;
      return new Chart(llmChartElement, {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: 'Left Leg Muscle Mass',
              data: llmValues,
              borderColor: primaryEnaraColor,
              backgroundColor: primaryEnaraColor,
              fill: false,
              pointBackgroundColor: primaryEnaraColor,
            },
            {
              label: 'Left Leg Fat Mass',
              data: llfmValues,
              borderColor: secondaryEnaraColor,
              backgroundColor: secondaryEnaraColor,
              fill: false,
              pointBackgroundColor: secondaryEnaraColor,
            },
          ],
        },
        options: {
          responsive: true,
          title: { display: true, text: 'Left Leg Muscles and Fat Mass' },
          tooltips: { mode: 'index', intersect: false },
          hover: { mode: 'nearest', intersect: true },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: { display: true },
                gridLines: { display: false },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: { display: true, labelString: 'Lbs.' },
                ticks: {
                  min: Math.min(...llmValues, ...llfmValues) - 2,
                  max: Math.max(...llmValues, ...llfmValues) + 2,
                },
                gridLines: { display: false },
              },
            ],
          },
        },
      });
    },
    [bodyCompositionRecords]
  );

  useEffect(() => {
    (async () => {
      // Get member ID
      const auth = getAuth();
      let memberId; // number
      if (auth && !auth?.isProvider) {
        memberId = auth.id;
      } else {
        memberId = Number(searchParams.get('memberId'));
      }

      // Get Body Composition Report
      const currentDate = DateTime.now();
      const fromDate = currentDate.minus({ years: 6 }).toFormat(DATE_TIME_DAY_FORMAT);
      const toDate = currentDate.plus({ days: 1 }).toFormat(DATE_TIME_DAY_FORMAT);
      try {
        const bodyCompositionRecordsRaw = await healthRecordsServiceClient.getBodyCompositionReport(
          memberId,
          {
            fromDate,
            toDate,
          }
        );
        if (!bodyCompositionRecordsRaw?.records?.length) {
          throw new Error('Could not get body composition records for the member');
        }
        setBodyCompositionRecords(
          bodyCompositionRecordsRaw.records.map((rawRecord) => rawRecord.recordedContent)
        );
      } catch (error) {
        logErrorAndReportToHoneybadger({ error });
        setError({ message: 'Could not get body composition records for the member' });
      }
    })();
  }, [searchParams]);

  useEffect(() => {
    if (!!bodyCompositionRecords.length) {
      const currentRecord = bodyCompositionRecords[bodyCompositionRecords.length - 1];
      const firstRecord = bodyCompositionRecords[0];
      let previousRecord: BodyCompositionRecord['recordedContent'] = currentRecord;
      if (bodyCompositionRecords.length > 1) {
        previousRecord = bodyCompositionRecords[bodyCompositionRecords.length - 2];
      }
      setCurrentBodyCompositionMetricsInLbs((prevState) => ({
        ...prevState,
        loading: false,
        metrics: {
          ...prevState.metrics,
          bodyFatMass: {
            comparisonPreviousValue: kgToPounds(
              getComparisonValueInLbs(Number(currentRecord.BFM), Number(previousRecord.BFM))
            ),
            comparisonSinceStart: kgToPounds(
              getComparisonValueInLbs(Number(currentRecord.BFM), Number(firstRecord.BFM))
            ),
            value: kgToPounds(Number(currentRecord.BFM)),
          },
          skeletalMuscleMass: {
            comparisonPreviousValue: kgToPounds(
              getComparisonValueInLbs(Number(currentRecord.SMM), Number(previousRecord.SMM))
            ),
            comparisonSinceStart: kgToPounds(
              getComparisonValueInLbs(Number(currentRecord.SMM), Number(firstRecord.SMM))
            ),
            value: kgToPounds(Number(currentRecord.SMM)),
          },
          weight: {
            comparisonPreviousValue: kgToPounds(
              getComparisonValueInLbs(Number(currentRecord.WT), Number(previousRecord.WT))
            ),
            comparisonSinceStart: kgToPounds(
              getComparisonValueInLbs(Number(currentRecord.WT), Number(firstRecord.WT))
            ),
            value: kgToPounds(Number(currentRecord.WT)),
          },
        },
      }));
    }
  }, [bodyCompositionRecords]);

  useEffect(() => {
    if (!currentBodyCompositionMetricsInLbs.loading) {
      // Pie Chart: Body Fat vs Skeletal Muscle Mass
      const pieChart = buildBodyFatVsSkeletalMuscleMassChart();

      return () => {
        pieChart.destroy();
      };
    }
  }, [currentBodyCompositionMetricsInLbs, buildBodyFatVsSkeletalMuscleMassChart]);

  useEffect(() => {
    if (!!bodyCompositionRecords.length) {
      // Create charts
      // Use format MM-DD-YYYY
      const datesAsLabels = bodyCompositionRecords.map(({ date }) => {
        return `${date.substring(4, 6)}-${date.substring(6, 8)}-${date.substring(0, 4)}`;
      });

      // Carousel charts
      // Weight Trend
      const wtChart = buildWeightTrendChart(datesAsLabels);

      // Body Fat Mass Trend
      const bfmtChart = buildBodyFatMassTrendChart(datesAsLabels);

      // Skeletal Muscle Mass Trend
      const smmtChart = buildSkeletalMuscleMassTrendChart(datesAsLabels);

      // Percentage Body Fat
      const pbfChart = buildPercentageBodyFatChart(datesAsLabels);

      // Skeletal Muscle and Body Fat Mass Trend
      const smmChart = buildSkeletalMuscleMassChart(datesAsLabels);

      // Right Arm Muscles
      const ramChart = buildRightArmMuscleMassChart(datesAsLabels);

      // Left Arm Muscles
      const lamChart = buildLeftArmMuscleMassChart(datesAsLabels);

      // Right Leg Muscles
      const rlmChart = buildRightLegMuscleMassChart(datesAsLabels);

      // Left Leg Muscles
      const llmChart = buildLeftLegMuscleMassChart(datesAsLabels);

      return () => {
        bfmtChart.destroy();
        wtChart.destroy();
        smmtChart.destroy();
        pbfChart.destroy();
        smmChart.destroy();
        ramChart.destroy();
        lamChart.destroy();
        rlmChart.destroy();
        llmChart.destroy();
      };
    }
  }, [
    bodyCompositionRecords,
    buildWeightTrendChart,
    buildBodyFatMassTrendChart,
    buildSkeletalMuscleMassTrendChart,
    buildPercentageBodyFatChart,
    buildSkeletalMuscleMassChart,
    buildRightArmMuscleMassChart,
    buildLeftArmMuscleMassChart,
    buildRightLegMuscleMassChart,
    buildLeftLegMuscleMassChart,
  ]);

  if (!!error) {
    return <ErrorPage message={error.message} />;
  }

  return (
    <>
      {/* Some meta tags */}
      <Helmet>
        {/**
         * TODO: Should migrate from Bootstrap to Material UI
         */}
        <title>Enara: Body Composition Dashboard</title>

        <link
          rel='stylesheet'
          href='https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css'
          integrity='sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N'
          crossOrigin='anonymous'
        />
        <link
          rel='stylesheet'
          href='https://use.fontawesome.com/releases/v5.0.9/css/all.css'
          integrity='sha384-5SOiIsAziJl6AWe0HWRKTXlfcSHKmYV4RBF18PPJ173Kzn7jzMyFuTtk8JA7QQG1'
          crossOrigin='anonymous'
        />

        <script
          src='https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js'
          integrity='sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj'
          crossOrigin='anonymous'></script>
        <script
          src='https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/js/bootstrap.bundle.min.js'
          integrity='sha384-Fy6S3B9q64WdZWQUiU+q4/2Lc9npb8tCaSX9FK7E8HnRr0Jz8D6OP9dO5Vg3Q9ct'
          crossOrigin='anonymous'></script>

        {/**
         * TODO: Missing mixpanel script. To track mixpanel events we should do a better implementation
         */}
      </Helmet>
      <div className='full-page composition-dashboard'>
        {/* User Mass Measurements - Lean Mass - Body fat */}
        <div className='container'>
          <div className='row text-center'>
            <div className='text-center pie-center'>
              <h3 className='mt-0'>Last Measured Weight</h3>
              <h5 className='mt-0 current-weight'>
                {currentBodyCompositionMetricsInLbs.loading
                  ? '...'
                  : parseMassInLbs(currentBodyCompositionMetricsInLbs.metrics.weight.value)}
              </h5>
              <h5 className='mt-0'>Body Fat vs Skeletal Muscle Mass</h5>
              <canvas id='myPieChart' width='300' height='300'></canvas>
            </div>
          </div>
        </div>

        <hr />

        <div className='container'>
          <div className='row text-center'>
            {/* FIRST SLIDE */}
            <div className='col-sm'>
              <Card className='main-carousel'>
                <div id='carousel-1' className='carousel slide' data-interval='false'>
                  <ol className='carousel-indicators'>
                    <li data-target='#carousel-1' data-slide-to='0' className='active'></li>
                    <li data-target='#carousel-1' data-slide-to='1'></li>
                  </ol>
                  <div className='carousel-inner'>
                    <div className='carousel-item active'>
                      <div className='col-md text-center trio'>
                        <img
                          className='trio-img'
                          src='/images/current-weight.jpeg'
                          alt='Generic placeholder'
                        />
                        <br />
                        <h3 className=''>Current Weight</h3>
                        <h5 className='current-weight'>
                          {currentBodyCompositionMetricsInLbs.loading
                            ? '...'
                            : parseMassInLbs(
                                currentBodyCompositionMetricsInLbs.metrics.weight.value
                              )}
                        </h5>
                        <h5>. . .</h5>
                        {!currentBodyCompositionMetricsInLbs.loading && (
                          <CompareMetric
                            comparisonPreviousValue={
                              currentBodyCompositionMetricsInLbs.metrics.weight
                                .comparisonPreviousValue
                            }
                            comparisonSinceStart={
                              currentBodyCompositionMetricsInLbs.metrics.weight.comparisonSinceStart
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className='carousel-item'>
                      <div className='col-md'>
                        <canvas id='weight-chart' width='320' height='350'></canvas>
                      </div>
                    </div>
                  </div>
                  <a
                    className='carousel-control-prev'
                    id='carousel-1-control'
                    href='#carousel-1'
                    role='button'
                    data-slide='prev'>
                    <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                    <span className='sr-only'>Previous</span>
                  </a>
                  <a
                    className='carousel-control-next'
                    id='carousel-1-control-2'
                    href='#carousel-1'
                    role='button'
                    data-slide='next'>
                    <span className='carousel-control-next-icon' aria-hidden='true'></span>
                    <span className='sr-only'>Next</span>
                  </a>
                </div>
              </Card>
            </div>

            {/* SECOND SLIDE */}
            <div className='col-sm'>
              <Card className='main-carousel'>
                <div id='carousel-2' className='carousel slide' data-interval='false'>
                  <ol className='carousel-indicators'>
                    <li data-target='#carousel-2' data-slide-to='0' className='active'></li>
                    <li data-target='#carousel-2' data-slide-to='1'></li>
                  </ol>
                  <div className='carousel-inner'>
                    <div className='carousel-item active'>
                      <div className='col-md text-center trio'>
                        <img
                          className='trio-img'
                          src='/images/body-fat-mass.jpeg'
                          alt='Generic placeholder'
                        />
                        <h3 className=''>Body Fat Mass</h3>
                        <h5 className='fat-mass'>
                          {currentBodyCompositionMetricsInLbs.loading
                            ? '...'
                            : parseMassInLbs(
                                currentBodyCompositionMetricsInLbs.metrics.bodyFatMass.value
                              )}
                        </h5>
                        <h5>. . .</h5>
                        {!currentBodyCompositionMetricsInLbs.loading && (
                          <CompareMetric
                            comparisonPreviousValue={
                              currentBodyCompositionMetricsInLbs.metrics.bodyFatMass
                                .comparisonPreviousValue
                            }
                            comparisonSinceStart={
                              currentBodyCompositionMetricsInLbs.metrics.bodyFatMass
                                .comparisonSinceStart
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className='carousel-item'>
                      <div className='col-md'>
                        <canvas id='bf-trend-chart1' width='320' height='350'></canvas>
                      </div>
                    </div>
                  </div>
                  <a
                    className='carousel-control-prev'
                    id='carousel-2-control'
                    href='#carousel-2'
                    role='button'
                    data-slide='prev'>
                    <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                    <span className='sr-only'>Previous</span>
                  </a>
                  <a
                    className='carousel-control-next'
                    id='carousel-2-control-2'
                    href='#carousel-2'
                    role='button'
                    data-slide='next'>
                    <span className='carousel-control-next-icon' aria-hidden='true'></span>
                    <span className='sr-only'>Next</span>
                  </a>
                </div>
              </Card>
            </div>

            {/* THIRD SLIDE */}
            <div className='col-sm'>
              <Card className='main-carousel'>
                <div id='carousel-3' className='carousel slide' data-interval='false'>
                  <ol className='carousel-indicators'>
                    <li data-target='#carousel-3' data-slide-to='0' className='active'></li>
                    <li data-target='#carousel-3' data-slide-to='1'></li>
                  </ol>
                  <div className='carousel-inner'>
                    <div className='carousel-item active'>
                      <div className='col-md text-center trio'>
                        <img
                          className='trio-img'
                          src='/images/skeletal-muscle-mass.jpeg'
                          alt='Generic placeholder'
                        />
                        <h3 className=''>Skeletal Muscle Mass</h3>
                        <h5 className='lean-mass'>
                          {currentBodyCompositionMetricsInLbs.loading
                            ? '...'
                            : parseMassInLbs(
                                currentBodyCompositionMetricsInLbs.metrics.skeletalMuscleMass.value
                              )}
                        </h5>
                        <h5>. . .</h5>
                        {!currentBodyCompositionMetricsInLbs.loading && (
                          <CompareMetric
                            comparisonPreviousValue={
                              currentBodyCompositionMetricsInLbs.metrics.skeletalMuscleMass
                                .comparisonPreviousValue
                            }
                            comparisonSinceStart={
                              currentBodyCompositionMetricsInLbs.metrics.skeletalMuscleMass
                                .comparisonSinceStart
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className='carousel-item'>
                      <div className='col-md'>
                        <canvas id='lean-mass-chart' height='350'></canvas>
                      </div>
                    </div>
                  </div>
                  <a
                    className='carousel-control-prev'
                    id='carousel-3-control'
                    href='#carousel-3'
                    role='button'
                    data-slide='prev'>
                    <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                    <span className='sr-only'>Previous</span>
                  </a>
                  <a
                    className='carousel-control-next'
                    id='carousel-3-control-2'
                    href='#carousel-3'
                    role='button'
                    data-slide='next'>
                    <span className='carousel-control-next-icon' aria-hidden='true'></span>
                    <span className='sr-only'>Next</span>
                  </a>
                </div>
              </Card>
            </div>
          </div>
        </div>

        <hr />

        <div className='container'>
          <div className='row'>
            <div className='col-md'>
              <canvas id='pbf-chart' width='320' height='350'></canvas>
            </div>
            <div className='col-md'>
              <canvas id='smm-fm-chart1' width='320' height='350'></canvas>
            </div>
          </div>
        </div>

        <hr />

        {/* Right Arm and Left Arm Muscle vs Fat */}
        <div className='container'>
          <div className='row'>
            <div className='col-md'>
              <canvas id='right-arm-chart' width='320' height='350'></canvas>
            </div>
            <div className='col-md'>
              <canvas id='left-arm-chart' width='320' height='350'></canvas>
            </div>
          </div>
        </div>

        <hr />

        {/* Right Leg and Left Leg Muscle vs Fat */}
        <div className='container'>
          <div className='row'>
            <div className='col-md'>
              <canvas id='right-leg-chart' width='320' height='350'></canvas>
            </div>
            <div className='col-md'>
              <canvas id='left-leg-chart' width='320' height='350'></canvas>
            </div>
          </div>
        </div>

        <hr />

        <div className='text-center'>
          <div
            className='fb-share-button'
            id='fb-share'
            data-href='https://www.enarahealth.com/'
            data-layout='button'></div>
        </div>

        <hr />
      </div>
    </>
  );
};

export default BodyComposition;
