import { FC } from 'react';

type Props = {
  comparisonPreviousValue: number;
  comparisonSinceStart: number;
};

const CompareMetric: FC<Props> = ({ comparisonPreviousValue, comparisonSinceStart }) => {
  return (
    <div className='text-center comp-results'>
      <div className='comp-results-section'>
        <h6 className='h7'>
          {comparisonPreviousValue > 0 && <i className='far fa-arrow-alt-circle-up' />}
          {comparisonPreviousValue === 0 && <i className='far fa-meh' />}
          {comparisonPreviousValue < 0 && <i className='far fa-arrow-alt-circle-down' />}
        </h6>
        <h6 className='h7'>
          {comparisonPreviousValue === 0 ? (
            <>
              <strong>No changes</strong> since your last body composition test.
            </>
          ) : (
            <>
              <strong>{Math.abs(comparisonPreviousValue).toFixed(2)} lbs</strong> since your last
              body composition test.
            </>
          )}
        </h6>
      </div>
      <br />
      <div className='comp-results-section'>
        <h6 className='h7'>
          {comparisonSinceStart > 0 && <i className='far fa-arrow-alt-circle-up' />}
          {comparisonSinceStart === 0 && <i className='far fa-meh' />}
          {comparisonSinceStart < 0 && <i className='far fa-arrow-alt-circle-down' />}
        </h6>
        <h6 className='h7'>
          {comparisonSinceStart === 0 ? (
            <>
              <strong>No changes</strong> since you started Enara.
            </>
          ) : (
            <>
              <strong>{Math.abs(comparisonSinceStart).toFixed(2)} lbs</strong> since you started
              Enara.
            </>
          )}
        </h6>
      </div>
    </div>
  );
};

export default CompareMetric;
