import { FC, useEffect, useState } from 'react';
import { LocalParticipant, LocalVideoTrack, RemoteParticipant } from 'twilio-video';
import Track from '../Track/Track';

type Props = {
  isLocalParticipant?: boolean;
  participant: LocalParticipant | RemoteParticipant;
};

function getNonNullTracksFromParticipant(participant: LocalParticipant) {
  const existingPublications = Array.from(participant.tracks.values());
  const existingTracks = existingPublications.map((publication) => publication.track);
  const nonNullTracks = existingTracks.filter((track) => track !== null);
  return nonNullTracks as LocalVideoTrack[];
}

const Participant: FC<Props> = ({ isLocalParticipant, participant }) => {
  const [tracks, setTracks] = useState<LocalVideoTrack[]>(
    getNonNullTracksFromParticipant(participant as LocalParticipant)
  );

  const trackPublished = () => {
    setTracks(getNonNullTracksFromParticipant(participant as LocalParticipant));
  };

  const addTrack = (track: LocalVideoTrack) => {
    setTracks((prevTracks) => [...prevTracks, track]);
  };

  const removeTrack = (track: LocalVideoTrack) => {
    if (!!tracks?.length) {
      setTracks((prevTracks) => prevTracks.filter((trk) => trk.name !== track.name));
    }
  };

  useEffect(() => {
    if (!isLocalParticipant) {
      participant.on('trackSubscribed', (track) => addTrack(track));
      participant.on('trackUnsubscribed', (track) => removeTrack(track));
    } else {
      participant.on('trackPublished', trackPublished);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: The Track component is not necessary, now just display a div.
  // In the future if is needed remove it or  improve the whole participant component
  return (
    <div className='participant' id={participant.identity}>
      <div className='identity'>{participant.identity}</div>
      {tracks.map((track) => (
        <Track key={`track-${track.id}`} track={track} />
      ))}
    </div>
  );
};

export default Participant;
