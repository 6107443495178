import { HEALTH_RECORDS_BASE_URL } from '../environment';
import HttpRequest from '../request';
import { BodyCompositionReportApiResponse } from './types';

class HealthRecordsServiceClient extends HttpRequest {
  public async getBodyCompositionReport(
    userId: number,
    { fromDate, toDate }: { fromDate: string; toDate: string }
  ): Promise<Pick<BodyCompositionReportApiResponse, 'records'>> {
    const bodyCompositionReportResponse = await this.get<BodyCompositionReportApiResponse>({
      url: `/v1/members/${userId}/types/body-composition-report`,
      params: { fromDate, toDate },
      options: { authAs: 'header' },
    });

    if (!bodyCompositionReportResponse) {
      return { records: [] };
    }

    return { records: bodyCompositionReportResponse.records };
  }
}

const healthRecordsServiceClient = new HealthRecordsServiceClient(HEALTH_RECORDS_BASE_URL);

export default healthRecordsServiceClient;
