import { FC } from 'react';
import './error-page.css';

type Props = {
  message: string;
};

const ErrorPage: FC<Props> = ({ message = '' }) => {
  return (
    <div className='error-container composition-dashboard'>
      <div className='text-center error-text'>
        <br />
        <br />
        <h3 className='error-message'>Oh, snap!</h3>
        <br />
        <h4>Looks like your data is not available :(</h4>
        <br />
        {!!message && <h6>Error: {message}</h6>}
        <br />
        <div className='text-center'>
          <p>We couldn't retrieve your information.</p>
          <p>While we figure this out, try again</p>
          <p>by going back and tap on the InBody</p>
          <p>dashboard button. If the problem</p>
          <p>persists, contact Tech Support.</p>
          <p>-Enara Team-</p>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
