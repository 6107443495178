import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const WatchIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon style={{ width: 18, height: 16 }} viewBox='0 0 18 16' {...props}>
      <path
        d='M8.50016 14.6667C7.57794 14.6667 6.71127 14.4917 5.90016 14.1417C5.08905 13.7917 4.3835 13.3167 3.7835 12.7167C3.1835 12.1167 2.7085 11.4111 2.3585 10.6C2.0085 9.78888 1.8335 8.92222 1.8335 7.99999C1.8335 7.07777 2.0085 6.21111 2.3585 5.39999C2.7085 4.58888 3.1835 3.88333 3.7835 3.28333C4.3835 2.68333 5.08905 2.20833 5.90016 1.85833C6.71127 1.50833 7.57794 1.33333 8.50016 1.33333C9.42238 1.33333 10.2891 1.50833 11.1002 1.85833C11.9113 2.20833 12.6168 2.68333 13.2168 3.28333C13.8168 3.88333 14.2918 4.58888 14.6418 5.39999C14.9918 6.21111 15.1668 7.07777 15.1668 7.99999C15.1668 8.92222 14.9918 9.78888 14.6418 10.6C14.2918 11.4111 13.8168 12.1167 13.2168 12.7167C12.6168 13.3167 11.9113 13.7917 11.1002 14.1417C10.2891 14.4917 9.42238 14.6667 8.50016 14.6667ZM8.50016 13.3333C9.21127 13.3333 9.89461 13.2 10.5502 12.9333C11.2057 12.6667 11.7835 12.2833 12.2835 11.7833L8.50016 7.99999V2.66666C7.01127 2.66666 5.75016 3.18333 4.71683 4.21666C3.6835 5.24999 3.16683 6.51111 3.16683 7.99999C3.16683 9.48888 3.6835 10.75 4.71683 11.7833C5.75016 12.8167 7.01127 13.3333 8.50016 13.3333Z'
        fill='#404040'
      />
    </SvgIcon>
  );
};

export default WatchIcon;
