import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import schedulingClient from '../../lib/scheduling/client';
import { AcuityAppointmentTag } from '../../lib/scheduling/types';
import { trackAppointmentTagForCurrentSession } from '../../lib/storage';
import userClient from '../../lib/user/client';
import Authenticating from '../Common/Authenticating/Authenticating';
import Prompt from '../Common/Prompt/Prompt';

const SchedulingLink: FC = () => {
  const [fetchingStatus, setFetchingStatus] = useState<
    'loading' | 'invalid-link' | 'invalid-profile' | 'done'
  >('loading');

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const result = await schedulingClient.decryptSchedulingLinkToRawAcuityLink();

      if (!result) {
        return setFetchingStatus('invalid-link');
      }

      const profile = await userClient.getProfileWithToken(result.authToken);

      if (!profile) {
        return setFetchingStatus('invalid-profile');
      }

      setFetchingStatus('done');
      trackAppointmentTagForCurrentSession(AcuityAppointmentTag.MemberAppScheduleLink);

      navigate(`/scheduling?appointmentTypeId=${result.appointmentTypeId}`);
    })();
  }, [navigate]);

  if (fetchingStatus === 'loading') {
    return <Authenticating />;
  }

  if (fetchingStatus === 'invalid-link') {
    return (
      <Prompt
        title={'We are having problems reading link information'}
        content={'Either this is an unknown link or it already expired.'}
      />
    );
  }

  if (fetchingStatus === 'invalid-profile') {
    return (
      <Prompt
        title={'Oops! Unknown profile'}
        content={'We were unable to fetch profile for this member.'}
      />
    );
  }

  return null;
};

export default SchedulingLink;
