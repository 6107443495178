// Environment
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

// Base URLs for services
export const CORE_API_HOST_URL = process.env.REACT_APP_CORE_API_BASE_URL!;
export const SCHEDULE_API_BASE_URL = process.env.REACT_APP_SCHEDULE_API_BASE_URL!;
export const FEATURES_BASE_URL = process.env.REACT_APP_FEATURES_BASE_URL!;
export const HEALTH_RECORDS_BASE_URL = process.env.REACT_APP_HEALTH_RECORDS_BASE_URL!;
export const MEMBERS_BASE_URL = process.env.REACT_APP_MEMBERS_BASE_URL!;

// Environment used to query for features
export const FEATURES_TARGET_ENV = process.env.REACT_APP_FEATURES_TARGET_ENV!;

// Credentials to access external services
export const INBODY_ACCOUNT = process.env.REACT_APP_INBODY_ACCOUNT;
export const INBODY_API_KEY = process.env.REACT_APP_INBODY_API_KEY;

// Report errors
export const HONEYBADGER_KEY = process.env.REACT_APP_HONEYBADGER_KEY;

// Mixpanel token
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN!;
