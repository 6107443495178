import { CircularProgress, Typography } from '@mui/material';
import { FC } from 'react';
import EnaraLogo from '../../icons/EnaraLogo';
import './prompt.css';

type Props = {
  title: string;
  content: string;
  showSpinner?: boolean;
};

const Prompt: FC<Props> = ({ title, content, showSpinner }) => (
  <div className='prompt-container'>
    <EnaraLogo />
    <Typography variant='h2' marginTop={10} marginBottom={10}>
      {title}
    </Typography>
    <Typography variant='h4'>{content}</Typography>
    {showSpinner && <CircularProgress size={40} style={{ marginTop: 50, width: '40px' }} />}
  </div>
);

export default Prompt;
