const LoginPage = () => {
  return (
    <div>
      <h1>Login</h1>
      (Pending)
    </div>
  );
};

export default LoginPage;
