import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { DailyAvailability, MonthlyAvailability } from '../../lib/scheduling/types';
import { AvailabilityPeriod } from './Scheduling';

type Props = {
  period: AvailabilityPeriod;
  monthlyAvailability: MonthlyAvailability | null;
  dailyAvailability: DailyAvailability | null;
};

const NoAvailability: FC<Props> = ({ period, dailyAvailability, monthlyAvailability }) =>
  period === 'monthly' && monthlyAvailability?.dates.length === 0 ? (
    <Stack alignItems={'center'} justifyContent={'center'}>
      <Typography variant='h4'>No monthly availability</Typography>
    </Stack>
  ) : period === 'daily' && dailyAvailability?.times.length === 0 ? (
    <Stack alignItems={'center'} justifyContent={'center'}>
      <Typography variant='h4'>No daily availability</Typography>
    </Stack>
  ) : null;

export default NoAvailability;
