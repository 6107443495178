import { FC } from 'react';
import Prompt from '../Prompt/Prompt';

const Authenticating: FC = () => (
  <Prompt
    title={'We are verifying your information'}
    content={
      'This should only take a moment. You will be automatically redirected to book your appointment.'
    }
    showSpinner
  />
);

export default Authenticating;
