import { Typography } from '@mui/material';
import { FC } from 'react';
import CoreLayout from '../Layout/CoreLayout';
import './error-boundary.css';

const ErrorBoundary: FC = () => {
  return (
    <CoreLayout>
      <div className='error-boundary-container'>
        <Typography variant='h2' className='error-message'>
          Whops! there was an issue
        </Typography>
        <Typography variant='h4' className='error-message'>
          Please reload the page or try accessing through the Enara app.
        </Typography>
      </div>
    </CoreLayout>
  );
};

export default ErrorBoundary;
