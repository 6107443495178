import { FC, useState } from 'react';
import { CloseIcon, HomeIcon, LocationIcon, SelectIcon } from '../../../assets/icons/svg';
import { setItem, StorageKeys } from '../../../lib/storage';
import telemedicineServiceClient from '../../../lib/telemedicine/client';
import { AllowedPlace, PlaceOfServiceRecord } from '../../../lib/telemedicine/types';
import MixpanelClient, {
  LEGACY_TRACKING_TAG_TELEMEDICINE_MODAL,
  LegacyTrackingEvent,
} from '../../../lib/tracking/mixpanel';
import { VideoRoomResponse } from '../../../lib/video/types';
import Toast from '../../Toast/Toast';
import './telemedicine-modal.css';

const options = [
  {
    option: 'Yes',
    value: AllowedPlace.Home,
    label: 'at my home',
    icon: <HomeIcon />,
  },
  {
    option: 'No',
    value: AllowedPlace.Other,
    label: 'not at my home',
    icon: <LocationIcon />,
  },
];

export type TelemedicineModalProps = {
  modalVisibility: boolean;
  meetingData: VideoRoomResponse | null;
  setModalVisibility: (value: boolean) => void;
  onJoinRoom: () => void;
};

const TelemedicineModal: FC<TelemedicineModalProps> = ({
  modalVisibility,
  meetingData,
  setModalVisibility,
  onJoinRoom,
}) => {
  const [optionSelected, setOptionSelected] = useState<AllowedPlace | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const telemedicineConnection = async () => {
    setIsLoading(true);

    if (meetingData && optionSelected) {
      const { identity_detail } = meetingData;

      if (identity_detail?.schedule_id) {
        const {
          member_id: memberId,
          schedule_id: scheduleId,
          auth_token: authToken,
        } = identity_detail;

        const placeOfServiceRecord: PlaceOfServiceRecord = {
          memberId,
          scheduleId,
          authToken,
          place: optionSelected,
        };

        const schedulingIdRegistered =
          await telemedicineServiceClient.sendTelemedicinePlaceOfService(placeOfServiceRecord);

        if (schedulingIdRegistered) {
          setItem(StorageKeys.TelemedicineSchedulingId, schedulingIdRegistered);
        }
      }

      MixpanelClient.trackLegacyEventWithTag({
        tag: LEGACY_TRACKING_TAG_TELEMEDICINE_MODAL,
        eventName: LegacyTrackingEvent.TelemedicineClickContinue,
        properties: { location: optionSelected },
      });

      onJoinRoom();
      setModalVisibility(false);
    } else {
      Toast.info('Please select an option');
    }

    setIsLoading(false);
  };

  return (
    <div className={modalVisibility ? 'modal' : 'hide-modal'}>
      <div className='modal-content'>
        <span
          className='close'
          onClick={() => {
            MixpanelClient.trackLegacyEventWithTag({
              tag: LEGACY_TRACKING_TAG_TELEMEDICINE_MODAL,
              eventName: LegacyTrackingEvent.TelemedicineClickClose,
            });

            setModalVisibility(false);
          }}>
          <CloseIcon />
        </span>
        <div className='modal-details'>
          <h2>Are you located at your home?</h2>

          <div className='modal-description'>
            <p>We are required to gather this information per Insurance Guidelines</p>
          </div>

          <div className='modal-options-container'>
            {options.map((option) => (
              <div
                key={option.value}
                className={`modal-option ${
                  optionSelected === option.value && 'modal-option-selected'
                }`}
                onClick={() => setOptionSelected(option.value)}>
                <div className='modal-selected-icon'>
                  {optionSelected === option.value && <SelectIcon />}
                </div>
                <div>
                  <span className='modal-option-select'>{option.option}</span>
                  <p className='modal-option-description'>{option.label}</p>
                </div>
                <div className='modal-option-icon'>{option.icon}</div>
              </div>
            ))}
          </div>
          <button className='modal-button' onClick={isLoading ? undefined : telemedicineConnection}>
            <span className='modal-button-content'>Continue</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TelemedicineModal;
