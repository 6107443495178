import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const ChevronRightIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon style={{ width: 25, height: 24 }} viewBox='0 0 25 24' {...props}>
      <path d='M13.1 12L8.5 7.4L9.9 6L15.9 12L9.9 18L8.5 16.6L13.1 12Z' fill='#278BA5' />
    </SvgIcon>
  );
};

export default ChevronRightIcon;
