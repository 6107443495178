import { FC, useEffect, useRef, useState } from 'react';
import { SpinnerIcon } from '../../../assets/icons';
import { ELAPSED_MS_BEFORE_NO_SHOW } from '../../../config/config';
import { useVideoCall } from '../../../contexts/videoCall/context';
import { logErrorAndReportToHoneybadger } from '../../../lib/errorReporting';
import { millisecondsToMinutes } from '../../../lib/format';
import telemedicineServiceClient from '../../../lib/telemedicine/client';
import { VideoCallStatus } from '../../../lib/telemedicine/types';
import MixpanelClient, { TrackingEvent } from '../../../lib/tracking/mixpanel';
import { UserType } from '../../../lib/video/types';
import Toast from '../../Toast/Toast';
import '../../VideoCall/components/Room/WaitingForParticipant/waiting-for-participant.css';

type WaitingForParticipantProps = {
  numberOfRemoteParticipants: number;
  onMarkCallAsNoShow: () => void;
};

const WaitingForParticipant: FC<WaitingForParticipantProps> = ({
  numberOfRemoteParticipants,
  onMarkCallAsNoShow,
}) => {
  const [markingAsNoShow, setMarkingAsNoShow] = useState<boolean>(false);
  const [shouldAllowToMarkCallAsNoShow, setShouldAllowToMarkCallAsNoShow] =
    useState<boolean>(false);
  const [remoteParticipantName, setRemoteParticipantName] = useState('');

  const { videoCallState, getParticipantNameBy } = useVideoCall();
  const { meetingInfo } = videoCallState;

  const noShowTimeoutId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (meetingInfo?.userType === UserType.Provider) {
      noShowTimeoutId.current = setTimeout(
        () => setShouldAllowToMarkCallAsNoShow(true),
        ELAPSED_MS_BEFORE_NO_SHOW
      );
    }

    const participantName = getParticipantNameBy(
      meetingInfo?.userType === UserType.Provider ? UserType.Member : UserType.Provider
    );

    setRemoteParticipantName(participantName!);

    return () => {
      if (noShowTimeoutId.current !== null) {
        clearTimeout(noShowTimeoutId.current);
      }
    };
  }, [meetingInfo, getParticipantNameBy]);

  const handleMarkCallAsNoShow = async () => {
    if (meetingInfo?.userType !== UserType.Provider) {
      return;
    }

    setMarkingAsNoShow(true);
    const appointmentId = meetingInfo.appointment?.id;
    const { provider } = meetingInfo.meetingAttendees ?? {};

    if (!appointmentId) {
      Toast.error('Could not find an appointment for the member');
      setMarkingAsNoShow(false);
      return;
    }

    try {
      MixpanelClient.trackEvent({
        eventName: TrackingEvent.Click,
        properties: { field: 'Mark as No Show', source: 'chime-video' },
      });
      await telemedicineServiceClient.setVideoCallStatusTo({
        scheduleId: appointmentId,
        providerId: provider.id,
        providerAuthToken: provider.authToken,
        videoCallStatus: VideoCallStatus.NoShow,
      });
    } catch (error) {
      Toast.error('Could not mark member as no show');
      logErrorAndReportToHoneybadger({ error });
      setMarkingAsNoShow(false);
      return;
    }

    setMarkingAsNoShow(false);
    onMarkCallAsNoShow();
  };

  if (numberOfRemoteParticipants > 0) {
    return null;
  }

  return (
    <div className='waitMessage'>
      <div>
        {shouldAllowToMarkCallAsNoShow ? (
          <>
            <div className='noShowUpperBox'>
              Your scheduled patient
              <span>{remoteParticipantName}</span>
              has not joined the call for the past{' '}
              {millisecondsToMinutes(ELAPSED_MS_BEFORE_NO_SHOW)} minutes
            </div>
            <button onClick={handleMarkCallAsNoShow} disabled={markingAsNoShow}>
              {markingAsNoShow ? (
                <div className='buttonContent'>
                  <div className='iconWrapper'>
                    <img src={SpinnerIcon} height='24' width='24' alt='spinner-icon' />
                  </div>
                  <div>Marking as no show...</div>
                </div>
              ) : (
                'Mark as No Show'
              )}
            </button>

            <div className='noShowLowerBox'>
              <div>or</div>
              <div>You can stay on the call and wait for your patient to join</div>
            </div>
          </>
        ) : (
          <>
            Please wait, <br />
            {remoteParticipantName && (
              <>
                <span>{remoteParticipantName}</span>
                <br />
              </>
            )}
            {!remoteParticipantName
              ? meetingInfo?.userType === UserType.Provider
                ? 'The patient will join momentarily...'
                : 'Your provider will join momentarily...'
              : 'will join momentarily...  '}
          </>
        )}
      </div>
    </div>
  );
};

export default WaitingForParticipant;
