import axios, { AxiosInstance } from 'axios';
import { logErrorAndReportToHoneybadger } from './errorReporting';
import { getAuth } from './storage';

type RequestOptions = {
  authAs?: 'payload' | 'params' | 'header' | 'none';
  token?: string;
};

export default abstract class HttpRequest {
  protected readonly axiosInstance: AxiosInstance;

  constructor(baseURL: string) {
    this.axiosInstance = axios.create({ baseURL });
  }

  protected async get<T>({
    url,
    params,
    options = { authAs: 'none' },
  }: {
    url: string;
    params?: any;
    options?: RequestOptions;
  }): Promise<T | null> {
    const { authAs, token } = options;

    const auth = getAuth();

    if (authAs !== 'none' && !token && !auth) {
      console.error({
        error: `There is no authentication token. Could not process [GET] authenticated request to ${url}`,
      });

      return null;
    }

    try {
      return (
        await this.axiosInstance.get<T>(url, {
          params: {
            ...(authAs === 'params' ? { auth_token: token ?? auth!.token } : {}),
            ...(params || {}),
          },
          ...(authAs === 'header'
            ? { headers: { Authorization: `Bearer ${token ?? auth!.token}` } }
            : {}),
        })
      ).data;
    } catch (error) {
      logErrorAndReportToHoneybadger({ error });
    }

    return null;
  }

  protected async post<T>({
    url,
    payload,
    options = { authAs: 'none' },
  }: {
    url: string;
    payload?: any;
    options?: RequestOptions;
  }): Promise<T | null> {
    const { authAs, token } = options;

    const auth = getAuth();

    if (authAs !== 'none' && !token && !auth) {
      console.error({
        error: `There is no authentication token. Could not process [POST] authenticated request to ${url}`,
      });

      return null;
    }

    try {
      return (
        await this.axiosInstance.post(
          url,
          {
            ...(payload || {}),
            ...(authAs === 'payload' ? { auth_token: token ?? auth!.token } : {}),
          },
          {
            ...(authAs === 'header'
              ? { headers: { Authorization: `Bearer ${token ?? auth!.token}` } }
              : authAs === 'params'
              ? { params: { auth_token: auth!.token } }
              : {}),
          }
        )
      ).data;
    } catch (error) {
      logErrorAndReportToHoneybadger({ error });
    }

    return null;
  }

  protected async put<T>({
    url,
    payload,
    params,
    options = { authAs: 'none' },
  }: {
    url: string;
    payload?: any;
    params?: any;
    options?: RequestOptions;
  }): Promise<T | null> {
    const { authAs, token } = options;

    const auth = getAuth();

    if (authAs !== 'none' && !token && !auth) {
      console.error({
        error: `There is no authentication token. Could not process [PUT] authenticated request to ${url}`,
      });

      return null;
    }

    try {
      return (
        await this.axiosInstance.put(
          url,
          {
            ...(payload || {}),
            ...(authAs === 'payload' ? { auth_token: token ?? auth!.token } : {}),
          },
          {
            ...(authAs === 'header'
              ? { headers: { Authorization: `Bearer ${token ?? auth!.token}` } }
              : {}),
            params,
          }
        )
      ).data;
    } catch (error) {
      logErrorAndReportToHoneybadger({ error });
    }

    return null;
  }

  protected async patch<T>({
    url,
    payload,
    params,
    options = { authAs: 'none' },
  }: {
    url: string;
    payload?: any;
    params?: any;
    options?: RequestOptions;
  }): Promise<T | null> {
    const { authAs, token } = options;

    const auth = getAuth();

    if (authAs !== 'none' && !token && !auth) {
      console.error({
        error: `There is no authentication token. Could not process [PATCH] authenticated request to ${url}`,
      });

      return null;
    }

    try {
      return (
        await this.axiosInstance.patch<T>(
          url,
          {
            ...(payload || {}),
            ...(authAs === 'payload' ? { auth_token: token ?? auth!.token } : {}),
          },
          {
            ...(authAs === 'header'
              ? { headers: { Authorization: `Bearer ${token ?? auth!.token}` } }
              : {}),
            params,
          }
        )
      ).data;
    } catch (error) {
      logErrorAndReportToHoneybadger({ error });
    }

    return null;
  }

  protected async delete<T>({
    url,
    payload,
    options = { authAs: 'none' },
  }: {
    url: string;
    payload?: any;
    options?: RequestOptions;
  }): Promise<T | null> {
    const { authAs, token } = options;

    const auth = getAuth();

    if (authAs !== 'none' && !token && !auth) {
      console.error({
        error: `There is no authentication token. Could not process [DELETE] authenticated request to ${url}`,
      });

      return null;
    }

    try {
      return (
        await this.axiosInstance.delete(url, {
          ...(payload || {}),
          ...(authAs === 'header'
            ? { headers: { Authorization: `Bearer ${token ?? auth!.token}` } }
            : {}),
        })
      ).data;
    } catch (error) {
      logErrorAndReportToHoneybadger({ error });
    }

    return null;
  }
}
