import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faBook,
  faCalendar,
  faDesktop,
  faMicrophone,
  faMicrophoneSlash,
  faPhoneSlash,
  faRecordVinyl,
  faShareSquare,
  faUserCheck,
  faVideo,
  faVideoSlash,
} from '@fortawesome/free-solid-svg-icons';
import {
  ForwardRefRenderFunction,
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { useFeatures } from '../../../../contexts/features/context';
import { useVideoCall } from '../../../../contexts/videoCall/context';
import { Features } from '../../../../lib/features/client';
import FeatureManager from '../../../../lib/features/manager';
import { VideoCallStatus } from '../../../../lib/telemedicine/types';
import MixpanelClient, { TrackingEvent } from '../../../../lib/tracking/mixpanel';
import { UserType } from '../../../../lib/video/types';
import ToolBoxButton from '../../../VideoCall/components/Room/Toolbox/ToolBoxButton';
import './tool-box.css';

export type ClickOptionCallbackParams =
  | {
      action:
        | ToolboxAction.LeaveRoom
        | ToolboxAction.ScreenSharing
        | ToolboxAction.ShowOutline
        | ToolboxAction.ToggleTrackAudio
        | ToolboxAction.ToggleTrackVideo
        | ToolboxAction.StartRecording;
    }
  | { action: ToolboxAction.VideoCallStatus; status: VideoCallStatus };

export enum ToolboxAction {
  ScreenSharing,
  LeaveRoom,
  VideoCallStatus,
  ShowOutline,
  ToggleTrackAudio,
  ToggleTrackVideo,
  StartRecording,
}

export type ToolBoxState = {
  isAudioOn: boolean;
  isVideoOn: boolean;
  isSharingScreen: boolean;
  isRecording: boolean;
};

export type RefToolbox = {
  setMarkedAsAttended: () => void;
};

type Props = {
  userType: UserType;
  toolboxState: ToolBoxState;
  loadingMode: boolean;
  onClickOption: (param: ClickOptionCallbackParams) => Promise<boolean>;
};

const Toolbox: ForwardRefRenderFunction<RefToolbox, Props> = (
  { userType, toolboxState, loadingMode, onClickOption },
  ref: ForwardedRef<RefToolbox>
) => {
  const [hasAttended, setHasAttended] = useState<boolean>(false);
  const [hasReschedule, setHasReschedule] = useState<boolean>(false);

  const { featuresState } = useFeatures();
  const {
    videoCallState: { meetingInfo },
  } = useVideoCall();

  useImperativeHandle(ref, () => ({
    setMarkedAsAttended: () => setHasAttended(true),
  }));

  const handleMarkAttended = async () => {
    MixpanelClient.trackEvent({
      eventName: TrackingEvent.Click,
      properties: { field: 'Mark as Attended' },
    });

    const result = await onClickOption({
      action: ToolboxAction.VideoCallStatus,
      status: VideoCallStatus.Attended,
    });
    if (result) {
      setHasAttended(true);
      setHasReschedule(false);
    }
  };

  const handleReschedule = async () => {
    MixpanelClient.trackEvent({
      eventName: TrackingEvent.Click,
      properties: { field: 'Mark as Rescheduled' },
    });

    const result = await onClickOption({
      action: ToolboxAction.VideoCallStatus,
      status: VideoCallStatus.Rescheduled,
    });
    if (result) {
      setHasReschedule(true);
      setHasAttended(false);
    }
  };

  return (
    <div className='toolbox-bottom-bar'>
      <div className='toolbox-control-inner-container'>
        <ToolBoxButton
          toolTip={toolboxState.isAudioOn ? 'Mute Mic' : 'Enable Mic'}
          label={toolboxState.isAudioOn ? 'Mute Mic' : 'Enable Mic'}
          onClick={() => onClickOption({ action: ToolboxAction.ToggleTrackAudio })}
          loadingMode={loadingMode}
          icon={(toolboxState.isAudioOn ? faMicrophone : faMicrophoneSlash) as IconDefinition}
        />

        <ToolBoxButton
          toolTip={toolboxState.isVideoOn ? 'Stop Video' : 'Show Video'}
          label={toolboxState.isVideoOn ? 'Stop Video' : 'Show Video'}
          onClick={() => onClickOption({ action: ToolboxAction.ToggleTrackVideo })}
          loadingMode={loadingMode}
          icon={(toolboxState.isVideoOn ? faVideo : faVideoSlash) as IconDefinition}
        />
      </div>

      <div className='toolbox-control-inner-container'>
        <>
          {userType === UserType.Provider && (
            <ToolBoxButton
              toolTip={toolboxState.isSharingScreen ? 'Stop Sharing' : 'Share Screen'}
              label={toolboxState.isSharingScreen ? 'Stop Sharing' : 'Share Screen'}
              onClick={() => onClickOption({ action: ToolboxAction.ScreenSharing })}
              loadingMode={loadingMode}
              icon={(!toolboxState.isSharingScreen ? faShareSquare : faDesktop) as IconDefinition}
            />
          )}

          {FeatureManager.shouldRenderRecordingForAppointment({
            feature: featuresState[Features.MembersWebAppShowRecording],
            userType: meetingInfo?.userType || UserType.Guest,
            appointmentType:
              meetingInfo && 'appointment' in meetingInfo
                ? meetingInfo.appointment?.type
                : undefined,
          }) && (
            <ToolBoxButton
              toolTip={toolboxState.isRecording ? 'Recording' : 'Start recording'}
              label={toolboxState.isRecording ? 'Recording' : 'Start recording'}
              onClick={() => onClickOption({ action: ToolboxAction.StartRecording })}
              loadingMode={loadingMode}
              customStyle={toolboxState.isRecording ? 'toolbox-button-red' : ''}
              icon={faRecordVinyl as IconDefinition}
            />
          )}
          <ToolBoxButton
            toolTip='Leave call'
            label='Leave call'
            onClick={() => onClickOption({ action: ToolboxAction.LeaveRoom })}
            loadingMode={loadingMode}
            customStyle='toolbox-button-red'
            icon={faPhoneSlash as IconDefinition}
          />
        </>
      </div>

      {userType === UserType.Provider ? (
        <div className='toolbox-control-inner-container'>
          <ToolBoxButton
            toolTip='Mark as Attended'
            label={`Mark as\nAttended`}
            onClick={handleMarkAttended}
            customStyle={hasAttended ? 'toolbox-button-green' : ''}
            loadingMode={loadingMode}
            icon={faUserCheck as IconDefinition}
          />

          <ToolBoxButton
            toolTip='Mark as Reschedule'
            label={`Mark as\nReschedule`}
            onClick={handleReschedule}
            loadingMode={loadingMode}
            customStyle={hasReschedule ? 'toolbox-button-green' : ''}
            icon={faCalendar as IconDefinition}
          />

          {FeatureManager.shouldRenderOutlineForAppointment({
            feature: featuresState[Features.MembersWebAppShowOutline],
            userType: meetingInfo?.userType || UserType.Guest,
            appointmentType:
              meetingInfo && 'appointment' in meetingInfo
                ? meetingInfo.appointment?.type
                : undefined,
          }) && (
            <ToolBoxButton
              toolTip='Show Member Outline'
              label={`Show Outline`}
              onClick={() => onClickOption({ action: ToolboxAction.ShowOutline })}
              loadingMode={loadingMode}
              icon={faBook as IconDefinition}
            />
          )}
        </div>
      ) : (
        <div className='toolbox-inner-container-fixed'> </div>
      )}
    </div>
  );
};

export default forwardRef(Toolbox);
