import mixpanel from 'mixpanel-browser';
import { CORE_API_HOST_URL } from '../environment';
import { safeJsonParse } from '../format';
import HttpRequest from '../request';
import { storeAuth } from '../storage';
import { Profile } from './types';

class UserClient extends HttpRequest {
  public async getProfileWithToken(token: string): Promise<Profile | null> {
    const { user } = (await this.get<{
      user: {
        uuid: string;
        id: number;
        avatar_url: string;
        avatar_url_thumb: string;
        first_name: string;
        last_name: string;
        provider: 'true' | 'false';
        unread_messages_count: number;
        email: string;
        phone: string;
        sign_in_count: number;
        last_uploaded_food_at: string;
        last_sign_in_at: string;
        notifications_count: number;
        birthdate: string;
        last_timezone: string;
        clinic_id: number;
      };
    }>({ url: `/api/v1/profile/${token}` })) || { user: null };

    if (user) {
      const isProvider = safeJsonParse<boolean>(user.provider, false);

      storeAuth({ id: user.id, isProvider, token });
      mixpanel.identify(user.uuid);

      return {
        uuid: user.uuid,
        id: user.id,
        name: {
          first: user.first_name,
          last: user.last_name,
        },
        avatar: {
          imageUrl: user.avatar_url,
          thumbnailUrl: user.avatar_url_thumb,
        },
        unreadMessagesCount: user.unread_messages_count,
        email: user.email,
        phone: user.phone,
        signInCount: user.sign_in_count,
        lastUploadedFoodAt: user.last_uploaded_food_at,
        lastSignInAt: user.last_sign_in_at,
        unreadNotificationsCount: user.notifications_count,
        birthDate: user.birthdate,
        timeZone: user.last_timezone,
        clinicId: user.clinic_id,
        isProvider,
      };
    }

    return null;
  }
}

const userClient = new UserClient(CORE_API_HOST_URL);

export default userClient;
