/** Errors */
export const ALLOW_CAMERA = `We can't start the video call because this application doesn't have permission to use your camera. Please allow camera access in your browser and try again. If the problem persists, try refreshing the page`;
export const ALLOW_MICROPHONE = `We can't start the call because this application doesn't have permission to use your microphone. Please allow microphone access on your browser and try again. If the problem persists, try refreshing the page`;
export const ALLOW_SCREEN_SHARING = `We can't begin screen sharing since the application lacks permission to access your screen. Please allow screen sharing in your browser and try again`;
export const CAMERA_OR_MICROPHONE_IN_USE =
  'Another application or tab is currently using your camera or microphone. Please close the application or tab and try again. If that does not work, try restarting your browser or device';
export const COULD_NOT_SET_UP_THE_CALL =
  'There was an error setting up the call. Please request a new video call link from your provider';
export const NO_ROOM_INFORMATION =
  'We are unable to connect you to a call due to missing room information. Please confirm that you are using the correct link or request a new one from your provider';
export const SCREEN_SHARING_NOT_SUPPORTED =
  'Unfortunately, screen sharing is not currently supported on mobile browsers. To use this feature, please connect from a desktop device';
