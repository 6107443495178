export enum TopicStatus {
  Completed = 'completed',
  Skipped = 'skipped',
}

export type OutlineProgressEntry = {
  topicSlug: string;
  topicStatus: TopicStatus;
};

export type HydratedTopic = {
  title: string;
  slug: string;
  prompt?: {
    title: string;
    suggestions?: string[];
  };
  items?: string[];
  llmResult: {
    pvq: string[] | null;
    hie: { title: string; summary: string[] }[] | null;
  };
};

export type HydratedOutline = {
  memberId: number;
  appointmentId: number;
  version: string;
  sections: {
    title: string;
    topics: HydratedTopic[];
  }[];
};
