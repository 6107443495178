import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EnaraLogo = (props: SvgIconProps) => (
  <SvgIcon style={{ width: 107, height: 23 }} viewBox='0 0 107 23' {...props}>
    <path
      d='M40.553 21.9853V11.6128C40.553 8.11765 37.985 5.29903 34.3469 5.29903C30.7089 5.29903 28.141 8.11765 28.141 11.6128V21.9853H24.075V11.6128C24.075 5.86275 28.4619 1.2402 34.3469 1.2402C40.232 1.2402 44.619 5.86275 44.619 11.6128V21.9853H40.553Z'
      fill='#1A667A'
    />
    <path
      d='M64.307 21.9853V19.8431L63.665 20.2941C61.953 21.4216 59.813 21.9853 57.78 21.9853C51.895 21.9853 47.08 17.3628 47.08 11.6128C47.08 5.9755 51.895 1.2402 57.78 1.2402C63.558 1.2402 68.266 5.63726 68.48 11.1618V11.3873V21.8726L64.307 21.9853ZM57.78 5.29903C54.142 5.29903 51.253 8.11765 51.253 11.6128C51.253 15.1079 54.249 17.9265 57.78 17.9265C61.418 17.9265 64.307 15.1079 64.307 11.6128C64.307 8.11765 61.311 5.29903 57.78 5.29903Z'
      fill='#1A667A'
    />
    <path
      d='M101.971 21.9853V19.8431L101.329 20.2941C99.617 21.4216 97.477 21.9853 95.444 21.9853C89.559 21.9853 84.744 17.3628 84.744 11.6128C84.744 5.9755 89.559 1.2402 95.444 1.2402C101.222 1.2402 105.93 5.63726 106.144 11.1618V11.3873V21.8726L101.971 21.9853ZM95.444 5.29903C91.806 5.29903 88.917 8.11765 88.917 11.6128C88.917 15.1079 91.913 17.9265 95.444 17.9265C99.082 17.9265 101.971 15.1079 101.971 11.6128C101.971 8.11765 98.975 5.29903 95.444 5.29903Z'
      fill='#1A667A'
    />
    <path
      d='M81.4271 1.35294C75.5421 1.35294 70.7271 5.97548 70.7271 11.7255V22.098H74.9001V11.7255C74.9001 8.23039 77.8961 5.41176 81.4271 5.41176H84.3161V1.35294H81.4271Z'
      fill='#1A667A'
    />
    <path
      d='M17.334 14.6569C16.585 16.0098 15.408 17.0245 13.91 17.5882C11.449 18.4902 8.77403 17.9265 6.84803 16.1225L6.42002 15.6716L22.256 9.80882L21.507 7.89216C19.367 2.59314 13.161 -0.112745 7.70402 1.91667C2.24702 4.05882 -0.534975 10.0343 1.60502 15.3333C3.74502 20.6324 9.95103 23.3382 15.408 21.3088C17.762 20.4069 19.688 18.8284 20.865 16.799L17.334 14.6569ZM5.02902 11.3873C5.13602 8.90686 6.84803 6.65196 9.20203 5.75C11.663 4.84804 14.338 5.41176 16.264 7.21568L16.692 7.66667L5.02902 11.951V11.3873Z'
      fill='#1A667A'
    />
  </SvgIcon>
);

export default EnaraLogo;
