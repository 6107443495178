import Box from '@mui/material/Box';
import { FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { COULD_NOT_SET_UP_THE_CALL } from '../../config/lang';
import { useFeatures } from '../../contexts/features/context';
import { useVideoCall, VideoCallProvider } from '../../contexts/videoCall/context';
import { VideoCallActions } from '../../contexts/videoCall/types';
import { logErrorAndReportToHoneybadger } from '../../lib/errorReporting';
import { Features } from '../../lib/features/client';
import { getItem, StorageKeys } from '../../lib/storage';
import MixpanelClient, {
  LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
  LegacyTrackingEvent,
  TrackingEventV2,
} from '../../lib/tracking/mixpanel';
import videoServiceClient from '../../lib/video/client';
import { UserType, VideoRoomResponse } from '../../lib/video/types';
import Toast from '../Toast/Toast';
import CallEnded from '../VideoCall/components/CallEnded';
import GetReady from '../VideoCall/components/GetReady';
import TelemedicineModal from '../VideoCall/components/TelemedicineModal';
import './chime-video-call.css';
import EnaraMeeting from './components/EnaraMeeting';
import { MeetingInformation } from './meeting-call.types';

type MainState = {
  meetingInfo: MeetingInformation | null;
  loadingJoin: boolean;
  joinRoom: boolean;
  loadingPresent: boolean;
  loadingMode: boolean;
  showModalByClinic: boolean;
  showTelemedicineModal: boolean;
  callEnded: boolean;
};

const ChimeVideoCall: FC = () => {
  const [mainState, setMainState] = useState<MainState>({
    meetingInfo: null,
    loadingJoin: false,
    joinRoom: false,
    loadingPresent: false,
    loadingMode: false,
    showModalByClinic: false,
    showTelemedicineModal: false,
    callEnded: false,
  });

  const { videoToken: rawVideoToken } = useParams<{ videoToken: string }>();
  const [queryParams] = useSearchParams();
  const source = queryParams.get('source') ?? 'unknown';

  const { featuresState } = useFeatures();
  const { dispatchVideoCall } = useVideoCall();

  const leaveRoom = async (meetingId: string) => {
    MixpanelClient.trackLegacyEventWithTag({
      tag: LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
      eventName: LegacyTrackingEvent.VideoCallAppLeaveCall,
    });

    if (mainState.meetingInfo && mainState.meetingInfo.userType === UserType.Provider) {
      const provider = mainState.meetingInfo.meetingAttendees?.provider!;
      await videoServiceClient.closeMeeting(meetingId, provider.authToken!);
    }

    setMainState((prevState) => ({
      ...prevState,
      room: null,
      loadingJoin: false,
      loadingPresent: false,
      callEnded: true,
    }));
  };

  useEffect(() => {
    if (!featuresState.loaded) {
      return;
    }

    MixpanelClient.trackEvent({
      eventName: TrackingEventV2.VideoCallOpen,
      properties: { source },
    });

    (async () => {
      try {
        const meetingData = await videoServiceClient.chimeFetchVideoRoomWithToken({
          token: rawVideoToken!,
        });

        if (!meetingData) {
          throw new Error('The backend is not returning meeting info for this call');
        }

        dispatchVideoCall({ type: VideoCallActions.ChimeSetCallInfo, payload: meetingData });
        setMainState((prevState) => ({ ...prevState, meetingInfo: meetingData }));

        const { meetingAttendees } = meetingData;

        MixpanelClient.registerUser({
          userType: meetingData.userType,
          room: meetingData.videoConfig?.meeting?.MeetingId ?? 'unknown',
          identityDetail:
            meetingData.userType === 'provider'
              ? meetingAttendees.provider
              : meetingAttendees.member,
        });
        MixpanelClient.trackEvent({
          eventName: TrackingEventV2.VideoCallIsReadyToJoin,
          properties: { source },
        });

        const memberInfo = meetingAttendees.member;

        if (memberInfo && memberInfo.clinicId) {
          const { enabled, clinicIds } =
            featuresState[Features.MemberShowTelemedicineModalByClinic];

          const showTelemedicineModal =
            enabled && (!clinicIds || clinicIds.includes(memberInfo.clinicId));

          if (showTelemedicineModal) {
            setMainState((prevState) => ({
              ...prevState,
              showModalByClinic: showTelemedicineModal,
            }));
          }
        }
      } catch (error) {
        Toast.error(COULD_NOT_SET_UP_THE_CALL);

        logErrorAndReportToHoneybadger({ error });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresState.loaded]);

  const enterVideoRoom = useCallback(() => {
    setMainState((prevState) => ({ ...prevState, joinRoom: true }));
  }, []);

  const shouldRequestToConfirmTelemedicine = useCallback(
    (appointmentId: number | undefined): boolean => {
      const storedAppointmentId = getItem(StorageKeys.TelemedicineSchedulingId) as number | null;

      if (!appointmentId || (storedAppointmentId && storedAppointmentId === appointmentId)) {
        return false;
      }

      return true;
    },
    []
  );

  const {
    callEnded,
    showTelemedicineModal,
    meetingInfo,
    joinRoom,
    loadingJoin,
    loadingMode,
    showModalByClinic,
  } = mainState;

  const checkTelemedicineBeforeJoiningRoom = useCallback(() => {
    MixpanelClient.trackEvent({
      eventName: TrackingEventV2.VideoCallJoin,
      properties: { field: 'Join call', source },
    });

    if (meetingInfo?.userType === UserType.Guest) {
      return enterVideoRoom();
    }

    const shouldShowTelemedicine = shouldRequestToConfirmTelemedicine(meetingInfo?.appointment?.id);

    if (showModalByClinic && shouldShowTelemedicine) {
      MixpanelClient.trackLegacyEventWithTag({
        tag: LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
        eventName: LegacyTrackingEvent.TelemedicineOpen,
      });

      setMainState((prevState) => ({ ...prevState, showTelemedicineModal: true }));
    } else {
      enterVideoRoom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldRequestToConfirmTelemedicine,
    enterVideoRoom,
    meetingInfo?.userType,
    showModalByClinic,
  ]);

  const dismissTelemedicineModal = useCallback(() => {
    setMainState((prevState) => ({ ...prevState, showTelemedicineModal: false }));
  }, []);

  const renderTelemedicineModal = () => {
    if (meetingInfo?.userType === UserType.Guest) {
      return null;
    }

    return (
      <TelemedicineModal
        modalVisibility={showTelemedicineModal}
        // TODO:
        // Dirty as it is, it's what we have now. "mainState" is killing us
        meetingData={
          {
            identity_detail: {
              schedule_id: meetingInfo?.appointment?.id,
              member_id: meetingInfo?.meetingAttendees?.member.id,
              auth_token: meetingInfo?.meetingAttendees?.member.authToken,
            },
          } as VideoRoomResponse
        }
        setModalVisibility={dismissTelemedicineModal}
        onJoinRoom={enterVideoRoom}
      />
    );
  };

  return (
    <Box className='app-chime'>
      <Helmet>
        <title>Enara: Appointments</title>
      </Helmet>
      <div>
        {callEnded ? (
          <CallEnded />
        ) : (
          <>
            {renderTelemedicineModal()}

            {!joinRoom ? (
              <GetReady
                loadingJoin={loadingJoin}
                videoRoomInfoAlreadyObtained={meetingInfo !== null}
                checkTelemedicineBeforeJoiningRoom={checkTelemedicineBeforeJoiningRoom}
              />
            ) : (
              <EnaraMeeting loadingMode={loadingMode} onLeaveRoom={leaveRoom} />
            )}
          </>
        )}
      </div>
    </Box>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <VideoCallProvider>
    <ChimeVideoCall />
  </VideoCallProvider>
);
