import { FC, PropsWithChildren, Reducer, createContext, useContext, useReducer } from 'react';
import { AuthActionTypes, AuthActions, AuthContextValue, AuthState } from './types';

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

const initialState: AuthState = {
  user: null,
};

export const authReducer: Reducer<AuthState, AuthActionTypes> = (prevState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AuthActions.SetUser:
      return { ...prevState, user: payload };

    default:
      return prevState;
  }
};

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [authState, dispatchAuth] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={{ authState, dispatchAuth }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('authContext must be used within AuthContext.Provider');
  }

  return authContext;
};
