import { FC, useEffect, useRef, useState } from 'react';
import { SpinnerIcon } from '../../../../../assets/icons';
import { ELAPSED_MS_BEFORE_NO_SHOW } from '../../../../../config/config';
import { millisecondsToMinutes } from '../../../../../lib/format';
import telemedicineServiceClient from '../../../../../lib/telemedicine/client';
import { VideoCallStatus } from '../../../../../lib/telemedicine/types';
import MixpanelClient, { TrackingEvent } from '../../../../../lib/tracking/mixpanel';
import { UserType, VideoRoomResponse } from '../../../../../lib/video/types';
import './waiting-for-participant.css';

type Props = {
  numberOfRemoteParticipants: number;
  roomInfo: VideoRoomResponse;
  onMarkCallAsNoShow: () => void;
};

const WaitingForParticipant: FC<Props> = ({
  numberOfRemoteParticipants,
  roomInfo,
  onMarkCallAsNoShow,
}) => {
  const [markingAsNoShow, setMarkingAsNoShow] = useState<boolean>(false);
  const [shouldAllowToMarkCallAsNoShow, setShouldAllowToMarkCallAsNoShow] =
    useState<boolean>(false);

  const noShowTimeoutId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (roomInfo.user_type === UserType.Provider) {
      noShowTimeoutId.current = setTimeout(
        () => setShouldAllowToMarkCallAsNoShow(true),
        ELAPSED_MS_BEFORE_NO_SHOW
      );
    }

    return () => {
      if (noShowTimeoutId.current !== null) {
        clearTimeout(noShowTimeoutId.current);
      }
    };
  }, [roomInfo.user_type]);

  const handleMarkCallAsNoShow = async () => {
    if (roomInfo.user_type !== UserType.Provider) {
      return;
    }

    const { provider } = roomInfo;

    if (provider?.schedule_id) {
      setMarkingAsNoShow(true);

      MixpanelClient.trackEvent({
        eventName: TrackingEvent.Click,
        properties: { field: 'Mark as No Show' },
      });

      await telemedicineServiceClient.setVideoCallStatusTo({
        scheduleId: provider.schedule_id,
        providerId: provider.id,
        providerAuthToken: provider.auth_token,
        videoCallStatus: VideoCallStatus.NoShow,
      });
    }

    onMarkCallAsNoShow();
  };

  if (numberOfRemoteParticipants > 0) {
    return null;
  }

  return (
    <div className='waitMessage'>
      <div>
        {shouldAllowToMarkCallAsNoShow ? (
          <>
            <div className='noShowUpperBox'>
              Your scheduled patient
              <span>{roomInfo.remote_participant}</span>
              has not joined the call for the past{' '}
              {millisecondsToMinutes(ELAPSED_MS_BEFORE_NO_SHOW)} minutes
            </div>
            <button onClick={handleMarkCallAsNoShow} disabled={markingAsNoShow}>
              {markingAsNoShow ? (
                <div className='buttonContent'>
                  <div className='iconWrapper'>
                    <img src={SpinnerIcon} height='24' width='24' alt='spinner-icon' />
                  </div>
                  <div>Marking as no show...</div>
                </div>
              ) : (
                'Mark as No Show'
              )}
            </button>

            <div className='noShowLowerBox'>
              <div>or</div>
              <div>You can stay on the call and wait for your patient to join</div>
            </div>
          </>
        ) : (
          <>
            Please wait,{' '}
            {roomInfo.remote_participant_role && `your ${roomInfo.remote_participant_role}`}
            <br />
            {roomInfo.remote_participant && (
              <>
                <span>{roomInfo.remote_participant}</span>
                <br />
              </>
            )}
            {!roomInfo.remote_participant_role && !roomInfo.remote_participant
              ? roomInfo.user_type === UserType.Provider
                ? 'The patient will join momentarily...'
                : 'Your provider will join momentarily...'
              : 'will join momentarily...  '}
          </>
        )}
      </div>
    </div>
  );
};

export default WaitingForParticipant;
