import { SCHEDULE_API_BASE_URL } from '../../environment';
import HttpRequest from '../../request';
import { SanitizedCoreAppointment, SanitizedCoreAppointmentResponse } from './types.v3';

class SchedulingV3 extends HttpRequest {
  public async listAppointments({
    memberId,
  }: {
    memberId: number;
  }): Promise<SanitizedCoreAppointment[]> {
    const listResponse = await this.get<SanitizedCoreAppointmentResponse>({
      url: `/v3/members/${memberId}/appointments`,
      options: { authAs: 'header' },
    });

    return listResponse?.appointments || [];
  }
}

export const schedulingV3Client = new SchedulingV3(SCHEDULE_API_BASE_URL);
