import { Button, Typography } from '@mui/material';
import { CalendarIcon } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { FC } from 'react';
import {
  DATE_TIME_DAY_NAME_AND_HOUR_FORMAT,
  DATE_TIME_DAY_ONLY_FORMAT,
  DATE_TIME_MONTH_NAME_ONLY_FORMAT,
} from '../../helpers/time';
import { stripAppointmentTypeFromAppointmentName } from '../../lib/format';
import { AppointmentTypeWithAvailableSlots } from '../../lib/scheduling/types';
import MixpanelClient, { TrackingEventV2 } from '../../lib/tracking/mixpanel';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import WatchIcon from '../icons/WatchIcon';
import { DefaultGroupClassCoverImage } from './../../assets/images';
import './group-class-card.css';

type Props = {
  index: number;
  appointmentTypeWithSlots: AppointmentTypeWithAvailableSlots;
  onRegister: (param: AppointmentTypeWithAvailableSlots) => void;
};

const GroupClassCard: FC<Props> = ({ appointmentTypeWithSlots, onRegister, index }) => {
  const handleRegister = (): void => {
    // TODO: Register directly vs show detail first
    onRegister(appointmentTypeWithSlots);
  };

  const { image, name, nextSlotAt, duration, slots } = appointmentTypeWithSlots;

  const offersAvailability = !!nextSlotAt && slots.length > 0;
  const dateTime = DateTime.fromISO(nextSlotAt);

  return (
    <div
      className='gc-card-container'
      onClick={(event) => {
        MixpanelClient.trackEvent({
          eventName: TrackingEventV2.GroupClassSelectClass,
          properties: {
            source: 'class-card',
            appointmentName: appointmentTypeWithSlots.name,
            appointmentIndex: index,
          },
        });

        event.stopPropagation();
        handleRegister();
      }}>
      <img className='gc-cover' src={image ? image : DefaultGroupClassCoverImage} alt={name} />

      <div className='gc-details'>
        <Typography variant='h3' className='gc-title'>
          {stripAppointmentTypeFromAppointmentName(name)}
        </Typography>
        {offersAvailability ? (
          <div className='gc-register-container'>
            <div className='gc-date'>
              <div>{dateTime.toFormat(DATE_TIME_MONTH_NAME_ONLY_FORMAT)}</div>
              <div className='gc-date-day'>{dateTime.toFormat(DATE_TIME_DAY_ONLY_FORMAT)}</div>
            </div>

            <div className='gc-appointment-container'>
              <div className='gc-appointment-row'>
                <CalendarIcon style={{ fontSize: 15 }} />
                <span className='gc-appointment-text'>
                  {dateTime.toFormat(DATE_TIME_DAY_NAME_AND_HOUR_FORMAT)}
                </span>
              </div>

              <div className='gc-appointment-row'>
                <WatchIcon style={{ fontSize: 15 }} />
                <span className='gc-appointment-text'>{duration} Min</span>
              </div>
            </div>

            <Button
              onClick={(event) => {
                MixpanelClient.trackEvent({
                  eventName: TrackingEventV2.GroupClassSelectClass,
                  properties: {
                    source: 'register-button',
                    appointmentName: appointmentTypeWithSlots.name,
                    appointmentIndex: index,
                  },
                });
                // TODO: Register directly vs show detail first
                event.stopPropagation();
                handleRegister();
              }}
              className='gc-register-btn'
              sx={{
                fontSize: '14px',
              }}>
              Register
            </Button>
          </div>
        ) : (
          <Typography variant='h6'>No available seats at the moment</Typography>
        )}
        {offersAvailability && (
          <div className='gc-class-info-and-dates-container' onClick={handleRegister}>
            <Typography className='gc-class-info-and-dates' variant='h3'>
              Class information and dates
            </Typography>
            <ChevronRightIcon />
          </div>
        )}{' '}
      </div>
    </div>
  );
};

export default GroupClassCard;
