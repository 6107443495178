import { createTheme } from '@mui/material/styles';

export const StyledTheme = {
  fontProximaNova: "'Proxima Nova', 'Source Serif Pro'",

  colors: {
    lightGreen: '#3cb4d2',
    green: '#1A667A',
    darkGreen: '#1A667A',
    lightGray: '#DEE5EC',
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: StyledTheme.colors.green,
      light: StyledTheme.colors.lightGreen,
      dark: StyledTheme.colors.darkGreen,
    },
  },
  typography: {
    fontFamily: ['Proxima Nova', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 18,
    h2: {
      fontSize: 26,
      fontWeight: 700,
      lineHeight: '32px',
    },
    h3: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '28px',
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '28px',
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '20px',
    },
    h6: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '21.90px',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: StyledTheme.colors.green,
          '&:hover': {
            background: StyledTheme.colors.darkGreen,
            color: 'white',
          },
          color: 'white',
          fontWeight: 700,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: StyledTheme.colors.green,
        },
      },
    },
  },
});

export default theme;
