import { FormControlLabel, Radio, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { DATE_TIME_TIME_FORMAT } from '../../helpers/time';
import { StyledTheme } from '../../theme';

type Prop = {
  day: string;
  isSelected: boolean;
  onSelectSlot: (day: string | null) => void;
};

const TimeSlot: FC<Prop> = ({ day, isSelected, onSelectSlot }) => {
  const handleSelectSlot = () => {
    onSelectSlot(day);
  };

  return (
    <FormControlLabel
      componentsProps={{ typography: { variant: 'h4', fontWeight: '600' } }}
      control={<Radio disableRipple disableFocusRipple checked={isSelected} />}
      sx={{
        border: `2px solid ${isSelected ? StyledTheme.colors.green : StyledTheme.colors.lightGray}`,
        borderRadius: 2,
      }}
      label={
        <Typography variant='h4'>
          {DateTime.fromISO(day).toFormat(DATE_TIME_TIME_FORMAT)}
        </Typography>
      }
      onClick={handleSelectSlot}
    />
  );
};

export default TimeSlot;
