export const millisecondsToMinutes = (milliseconds: number): number => {
  return milliseconds / (60 * 1000);
};

export const stripAppointmentTypeFromAppointmentName = (
  rawAppointmentName: string | undefined
): string => {
  if (!rawAppointmentName) {
    return '';
  }

  return rawAppointmentName.split('[')[0].trim();
};

export const safeJsonParse = <T>(value: string, fallback: T): T => {
  try {
    return JSON.parse(value) as T;
  } catch (error) {}

  return fallback;
};
