import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import schedulingClient from '../../lib/scheduling/client';
import { AcuityAppointmentTag } from '../../lib/scheduling/types';
import { trackAppointmentTagForCurrentSession } from '../../lib/storage';
import userClient from '../../lib/user/client';
import Authenticating from '../Common/Authenticating/Authenticating';

const SelfSignIn: FC = () => {
  const [fetching, setFetching] = useState<boolean>(true);

  const { token } = useParams<{ token: string }>();
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      throw new Error("Invalid token, unable to fetch member's profile");
    }

    (async () => {
      const appointmentTypeId = schedulingClient.getAppointmentTypeIdParamFromURL();
      const specialty = schedulingClient.getSpecialtyParamFromURL();

      const profile = await userClient.getProfileWithToken(token);

      if (!profile) {
        return navigate(`/error`);
      }
      const redirect = queryParams.get('redirect');
      if (redirect) {
        return navigate(redirect);
      }

      if (!specialty && !appointmentTypeId) {
        return navigate(`/`);
      }

      if (specialty) {
        trackAppointmentTagForCurrentSession(AcuityAppointmentTag.MemberAppSelfSchedule);

        navigate(`/scheduling?specialty=${specialty}`);
      } else {
        trackAppointmentTagForCurrentSession(AcuityAppointmentTag.MemberAppReschedule);

        navigate(`/scheduling?appointmentTypeId=${appointmentTypeId}`);
      }

      setFetching(false);
    })();
  });

  return fetching && <Authenticating />;
};

export default SelfSignIn;
