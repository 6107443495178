import { Dispatch } from 'react';
import { MeetingInformation } from '../../Components/ChimeVideoCall/meeting-call.types';
import { UserType, VideoRoomResponse } from '../../lib/video/types';

export type VideoCallContextValue = {
  videoCallState: VideoCallState;
  dispatchVideoCall: Dispatch<VideoCallActionTypes>;
  getParticipantNameBy: (userType: UserType) => string | undefined;
};

export type VideoCallState = {
  callInfo: VideoRoomResponse;
  meetingInfo: MeetingInformation | null;
};

export enum VideoCallActions {
  SetCallInfo = '@enara/types/set-call-info',
  ChimeSetCallInfo = '@enara/types/chime-set-call-info',
}

export type SetCallInfoType = {
  type: VideoCallActions.SetCallInfo;
  payload: VideoRoomResponse;
};

export type SetChimeCallInfoType = {
  type: VideoCallActions.ChimeSetCallInfo;
  payload: MeetingInformation;
};

export type VideoCallActionTypes = SetCallInfoType | SetChimeCallInfoType;
