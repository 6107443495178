import { FeatureName, FeaturesApiClient } from '@enara-modules/enara-module-features-api-client';
import { FEATURES_BASE_URL, FEATURES_TARGET_ENV } from '../environment';
import { logErrorAndReportToHoneybadger } from '../errorReporting';

export enum Features {
  MemberShowTelemedicineModalByClinic = 'member-show-telemedicine-modal-by-clinic',
  MembersWebAppHideForbiddenDates = 'members-web-app-hide-forbidden-dates',
  MembersWebAppShowOutline = 'members-web-app-show-outline',
  MembersWebAppShowRecording = 'members-web-app-show-start-recording',
}

type FeatureFlagValue = {
  enabled: boolean;
  clinicIds?: number[];
};

export type AllFeatures = {
  [Features.MemberShowTelemedicineModalByClinic]: FeatureFlagValue;
  [Features.MembersWebAppHideForbiddenDates]: FeatureFlagValue;
  [Features.MembersWebAppShowOutline]: FeatureFlagValue;
  [Features.MembersWebAppShowRecording]: FeatureFlagValue;
};

class FeaturesManager {
  private readonly baseUrl;
  private readonly targetEnv;

  constructor(baseUrl: string, targetEnv: string) {
    this.baseUrl = baseUrl;
    this.targetEnv = targetEnv;
  }

  public async getFeatures(): Promise<AllFeatures | null> {
    const featuresClient = new FeaturesApiClient({
      targetEnv: this.targetEnv,
      baseUrl: this.baseUrl,
    });

    try {
      const featuresResponse = await featuresClient.getFeatures({
        featureNames: Object.values(Features) as unknown as FeatureName[],
      });

      const updatedFeatures: AllFeatures = {} as AllFeatures;

      if (featuresResponse) {
        Object.entries(featuresResponse.values).forEach(([key, { enabled, clinicIds }]) => {
          updatedFeatures[key as Features] = { enabled, clinicIds };
        });

        return updatedFeatures;
      }
    } catch (error) {
      logErrorAndReportToHoneybadger({ error });
    }

    return null;
  }
}

const featuresManager = new FeaturesManager(FEATURES_BASE_URL, FEATURES_TARGET_ENV);

export default featuresManager;
