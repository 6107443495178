import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import { ThemeProvider } from '@mui/material';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ExistingAppointmentsPage } from './Components/Appointments/ExistingAppointments';
import BodyComposition from './Components/BodyComposition/BodyComposition';
import ChimeVideoCall from './Components/ChimeVideoCall/ChimeVideoCall';
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';
import GroupClassDetail from './Components/GroupClasses/GroupClassDetail';
import GroupClasses from './Components/GroupClasses/GroupClasses';
import LoginPage from './Components/Login/Login';
import MainMenuPage from './Components/MainMenu/MainMenuPage';
import PrivateRoute from './Components/PrivateRoute/PrivateRoute';
import Scheduling from './Components/Scheduling/Scheduling';
import SchedulingLink from './Components/SchedulingLink/SchedulingLink';
import SelfSignIn from './Components/SelfSignIn/SelfSignIn';
import { ToastContainer } from './Components/Toast/Toast';
import VideoCall from './Components/VideoCall/VideoCall';
import { AuthProvider } from './contexts/auth/context';
import { FeaturesProvider } from './contexts/features/context';
import './index.css';
import { ENVIRONMENT, HONEYBADGER_KEY } from './lib/environment';
import MixpanelClient from './lib/tracking/mixpanel';
import reportWebVitals from './reportWebVitals';
import { RouteConstants } from './route-constants';
import theme from './theme';

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainMenuPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/login',
    element: <LoginPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/s/:token',
    element: <SelfSignIn />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/self-sign-in/:token',
    element: <SelfSignIn />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: RouteConstants.appointmentsForMember,
    element: <ExistingAppointmentsPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: RouteConstants.appointmentDetailsForMember,
    element: <ExistingAppointmentsPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: RouteConstants.appointmentsForMe,
    element: <ExistingAppointmentsPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: RouteConstants.appointmentDetailsForMe,
    element: <ExistingAppointmentsPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/scheduling',
    element: <Scheduling />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/scheduling-link',
    element: <SchedulingLink />,
  },
  {
    path: '/video-call/:videoToken',
    element: <VideoCall />,
  },
  {
    path: '/group-classes/:token',
    element: <GroupClasses />,
  },
  {
    path: '/group-classes/:appointmentTypeId/detail',
    element: <GroupClassDetail />,
  },
  {
    path: '/chime-video/:videoToken',
    element: <ChimeVideoCall />,
  },
  {
    path: 'error',
    element: <ErrorBoundary />,
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        path: '/body-composition',
        element: <BodyComposition />,
      },
    ],
  },
]);

MixpanelClient.init();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <>
    <ThemeProvider theme={theme}>
      <HoneybadgerErrorBoundary
        honeybadger={Honeybadger.configure({
          apiKey: HONEYBADGER_KEY,
          environment: ENVIRONMENT,
        })}>
        <AuthProvider>
          <FeaturesProvider>
            <RouterProvider router={router} />
          </FeaturesProvider>
        </AuthProvider>
      </HoneybadgerErrorBoundary>
    </ThemeProvider>
    <ToastContainer />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
