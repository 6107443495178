export enum UserType {
  Provider = 'provider',
  Member = 'member',
  Guest = 'guest',
}

export interface ProviderIdentity {
  id: number;
  auth_token: string;
  schedule_id: number | null;
  appointment_type: string;
}

export interface MemberIdentityDetail {
  member_id: number;
  auth_token: string;
  clinic_id: number;
  schedule_id?: number;
}

export type VideoRoomResponse = { provider?: ProviderIdentity } & {
  success: string;
  token: string;
  room: string;
  user_type: UserType;
  identity: string;
  remote_participant: string;
  remote_participant_role: string;
  identity_detail?: MemberIdentityDetail;
};
