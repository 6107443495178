import { createRef, FC, useEffect } from 'react';
import { LocalVideoTrack } from 'twilio-video';

type Props = {
  track: LocalVideoTrack;
};

const Track: FC<Props> = ({ track }) => {
  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    if (track !== null && !!ref?.current && !!track.attach) {
      ref.current.classList.add(track.kind);
      ref.current.appendChild(track.attach());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className='track' ref={ref}></div>;
};

export default Track;
