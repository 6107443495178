import { FC, PropsWithChildren, Reducer, createContext, useContext, useReducer } from 'react';
import { UserType } from '../../lib/video/types';
import {
  VideoCallActionTypes,
  VideoCallActions,
  VideoCallContextValue,
  VideoCallState,
} from './types';

const VideoCallContext = createContext<VideoCallContextValue | undefined>(undefined);

const initialState: VideoCallState = {
  callInfo: {
    provider: undefined,
    success: '',
    token: '',
    room: '',
    user_type: UserType.Guest,
    identity: '',
    remote_participant: '',
    remote_participant_role: '',
    identity_detail: undefined,
  },
  meetingInfo: null,
};

export const videoCallReducer: Reducer<VideoCallState, VideoCallActionTypes> = (
  prevState,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case VideoCallActions.SetCallInfo:
      return { ...prevState, callInfo: payload };
    case VideoCallActions.ChimeSetCallInfo:
      const provider = payload.meetingAttendees?.provider;
      return {
        ...prevState,
        callInfo: {
          // Making the structure for the old video call
          ...prevState.callInfo,
          user_type: payload.userType,
          provider: {
            id: 'id' in provider! ? provider?.id : 0,
            auth_token: 'authToken' in provider! ? provider?.authToken : '',
            schedule_id:
              'appointment' in payload && payload.appointment?.id ? payload.appointment?.id : null,
            appointment_type: 'appointment' in payload ? payload.appointment?.type ?? '' : '',
          },
          room: payload.videoConfig?.meeting.MeetingId,
        },
        meetingInfo: payload,
      };

    default:
      return prevState;
  }
};

export const VideoCallProvider: FC<PropsWithChildren> = ({ children }) => {
  const [videoCallState, dispatchVideoCall] = useReducer(videoCallReducer, initialState);

  function getParticipantNameBy(userType: UserType) {
    if (userType === UserType.Provider) {
      return videoCallState.meetingInfo?.meetingAttendees?.provider.name;
    } else if (userType === UserType.Member) {
      return videoCallState.meetingInfo?.meetingAttendees?.member.name;
    }

    return undefined;
  }

  return (
    <VideoCallContext.Provider value={{ videoCallState, dispatchVideoCall, getParticipantNameBy }}>
      {children}
    </VideoCallContext.Provider>
  );
};

export const useVideoCall = () => {
  const videoCallContext = useContext(VideoCallContext);

  if (!videoCallContext) {
    throw new Error('videoCallContext must be used within VideoCallContext.Provider');
  }

  return videoCallContext;
};
