import { CORE_API_HOST_URL } from '../environment';
import HttpRequest from '../request';
import { PlaceOfService, PlaceOfServiceRecord, VideoCallStatusParams } from './types';

class TelemedicineServiceClient extends HttpRequest {
  public async sendTelemedicinePlaceOfService(
    placeOfServiceRecord: PlaceOfServiceRecord
  ): Promise<number | undefined> {
    const { memberId, scheduleId, place, authToken } = placeOfServiceRecord;

    const response = await this.patch<PlaceOfService>({
      url: `/api/v2/patient/${memberId}/schedulings/${scheduleId}`,
      payload: { place_of_service: place },
      options: { authAs: 'header', token: authToken },
    });

    return response?.id;
  }

  public async setVideoCallStatusTo(statusParams: VideoCallStatusParams): Promise<void> {
    const { scheduleId, providerId, providerAuthToken, videoCallStatus } = statusParams;
    await this.patch({
      url: `/api/v2/providers/${providerId}/schedulings/${scheduleId}`,
      payload: {
        scheduling: {
          attendance_status: videoCallStatus,
        },
      },
      options: { authAs: 'header', token: providerAuthToken },
    });
  }
}

const telemedicineServiceClient = new TelemedicineServiceClient(CORE_API_HOST_URL);

export default telemedicineServiceClient;
