import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { generatePath, matchPath, useLocation, useNavigate, useParams } from 'react-router';
import { Link, redirect, useSearchParams } from 'react-router-dom';
import { logErrorAndReportToHoneybadger } from '../../lib/errorReporting';
import schedulingClient from '../../lib/scheduling/client';
import { schedulingV3Client } from '../../lib/scheduling/v3/client.v3';
import { SanitizedCoreAppointment } from '../../lib/scheduling/v3/types.v3';
import { getAuth } from '../../lib/storage';
import MixpanelClient, { TrackingEventV2 } from '../../lib/tracking/mixpanel';
import { RouteConstants } from '../../route-constants';
import '../VideoCall/components/telemedicine-modal.css';

const getTrackingEventsByMemberOrSelf = (member: boolean) =>
  !member
    ? {
        list: TrackingEventV2.ScheduleListOwnAppointments,
        detail: TrackingEventV2.ScheduleDetailOwnAppointment,
        cancel: TrackingEventV2.ScheduleCancelOwnAppointment,
        cancelDialog: TrackingEventV2.ScheduleCancelDialogOwnAppointment,
        cancelDialogClickKeep: TrackingEventV2.ScheduleCancelDialogOwnAppointmentClickKeep,
        cancelDialogClickConfirm: TrackingEventV2.ScheduleCancelDialogOwnAppointmentClickConfirm,
      }
    : {
        list: TrackingEventV2.ScheduleListMemberAppointments,
        detail: TrackingEventV2.ScheduleDetailMemberAppointment,
        cancel: TrackingEventV2.ScheduleCancelMemberAppointment,
        cancelDialog: TrackingEventV2.ScheduleCancelDialogMemberAppointment,
        cancelDialogClickKeep: TrackingEventV2.ScheduleCancelDialogMemberAppointmentClickKeep,
        cancelDialogClickConfirm: TrackingEventV2.ScheduleCancelDialogMemberAppointmentClickConfirm,
      };

const CANCELLATION_FEE_PERIOD_48_HOURS_IN_MS = 48 * 60 * 60 * 1000;
const FEE_COPY =
  '48h cancellation fee, unless rescheduled for same week (waived for Medicaid/Medicare customers)';

const SelectedAppointment: FC<{
  appointment: SanitizedCoreAppointment;
  onClose: () => void;
}> = ({ appointment, onClose }) => {
  const [cancellationRequestStatus, setCancellationRequestStatus] = useState<
    'not-requested' | 'processing' | 'completed' | 'error'
  >('not-requested');

  const [queryParams, setQueryParams] = useSearchParams();
  const action = (queryParams.get('action') as 'cancel') || null;
  const closeTabAfterAction = !!queryParams.get('closeTabAfterAction');
  const params = useParams<{ appointmentId: string; memberId?: string }>();
  const { memberId: requestedMemberId } = params;

  const isFuture = appointment.future;
  const startsWithinCancellationWithFeePeriod =
    isFuture &&
    new Date(appointment.startAtUTC) <
      new Date(new Date().getTime() + CANCELLATION_FEE_PERIOD_48_HOURS_IN_MS);

  useEffect(() => {
    const eventsNames = getTrackingEventsByMemberOrSelf(!!requestedMemberId);
    if (action === 'cancel') {
      MixpanelClient.trackEvent({
        eventName: eventsNames.cancelDialog,
        properties: { field: 'SelectedAppointmentDetailPage', value: requestedMemberId },
      });
    } else {
      MixpanelClient.trackEvent({
        eventName: eventsNames.detail,
        properties: { field: 'SelectedAppointmentDetailPage', value: requestedMemberId },
      });
    }
  }, [appointment, action, requestedMemberId]);

  // This is weird, but ... hooks.
  const eventsNames = getTrackingEventsByMemberOrSelf(!!requestedMemberId);

  const handleCancel = async () => {
    MixpanelClient.trackEvent({
      eventName: eventsNames.cancel,
      properties: { field: 'SelectedAppointmentDetailPage', value: requestedMemberId },
    });

    const selfMemberId = getAuth()?.id;
    if (!requestedMemberId && !selfMemberId) {
      logErrorAndReportToHoneybadger({ error: 'Missing memberId' });
      setCancellationRequestStatus('error');
    } else {
      setCancellationRequestStatus('processing');
      try {
        const result = await schedulingClient.cancelAppointment({
          memberId: requestedMemberId ? Number(requestedMemberId) : selfMemberId!,
          appointmentId: appointment.id,
        });
        if (result) {
          setCancellationRequestStatus('completed');
        } else {
          logErrorAndReportToHoneybadger({ error: 'Cancel select appointment responded null' });
          setCancellationRequestStatus('error');
        }
      } catch (error) {
        logErrorAndReportToHoneybadger({ error });
        setCancellationRequestStatus('error');
      }
    }
  };

  return (
    <>
      <div className={action === 'cancel' ? 'modal' : 'hide-modal'}>
        <div className='modal-content'>
          <h3>
            {cancellationRequestStatus === 'not-requested'
              ? 'Cancel Appointment'
              : cancellationRequestStatus === 'processing'
              ? 'Cancelling...'
              : cancellationRequestStatus === 'completed'
              ? 'Schedule cancelled'
              : 'Error cancelling'}
          </h3>
          {cancellationRequestStatus === 'not-requested' ||
          cancellationRequestStatus === 'processing' ? (
            <p>
              Are you sure you want to cancel this appointment?{' '}
              {startsWithinCancellationWithFeePeriod
                ? `This will incur a ${FEE_COPY}.`
                : 'This is a free cancellation.'}
            </p>
          ) : cancellationRequestStatus === 'completed' ? (
            <p>
              Staying connected with your care team is vital for your health goals. Would you like
              to reschedule? (Pending)
            </p>
          ) : (
            <p>
              Staying connected with your care team is vital for your health goals. Would you like
              to reschedule?
            </p>
          )}
          {cancellationRequestStatus === 'completed' ? (
            <>
              <button style={{ marginBottom: '5px' }} disabled>
                Reschedule
              </button>
              <button
                style={{ marginBottom: '5px' }}
                onClick={() => {
                  if (closeTabAfterAction) {
                    return window.close();
                  }
                  onClose();
                }}>
                Close this window
              </button>
            </>
          ) : (
            <>
              <button
                style={{ marginBottom: '5px' }}
                disabled={cancellationRequestStatus === 'processing'}
                onClick={() => {
                  MixpanelClient.trackEvent({
                    eventName: eventsNames.cancelDialogClickKeep,
                    properties: {
                      field: 'SelectedAppointmentDetailPage',
                      value: requestedMemberId,
                    },
                  });
                  if (closeTabAfterAction) {
                    return window.close();
                  }
                  setQueryParams((qp) => {
                    qp.delete('action');
                    return qp;
                  });
                }}>
                Keep appointment
              </button>
              <button
                style={{ marginBottom: '5px', backgroundColor: '#de6351' }}
                disabled={cancellationRequestStatus === 'processing'}
                onClick={handleCancel}>
                Cancel appointment
              </button>
            </>
          )}
        </div>
      </div>

      <div>
        <h2>{appointment.appointmentType.split('[')[0]}</h2>

        <ul>
          <li>{appointment.startAtUTC}</li>
          <li>{appointment.provider?.displayName}</li>
          {isFuture ? (
            <li>
              Cancel this appointment
              <ul>
                {startsWithinCancellationWithFeePeriod ? (
                  <>
                    <li>Subject to ${FEE_COPY}.</li>
                  </>
                ) : (
                  <li>Free cancellation (More than 48h)</li>
                )}
              </ul>
              <br />
              <button
                disabled={!!action}
                onClick={() => {
                  MixpanelClient.trackEvent({
                    eventName: eventsNames.cancelDialog,
                    properties: {
                      field: 'SelectedAppointmentDetailPage',
                      value: requestedMemberId,
                    },
                  });
                  setQueryParams((qp) => {
                    qp.append('action', 'cancel');
                    return qp;
                  });
                }}>
                Cancel appointment
              </button>
            </li>
          ) : null}
        </ul>
      </div>
    </>
  );
};

export const ExistingAppointmentsPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const params = useParams<{ appointmentId: string; memberId?: string }>();
  const { appointmentId, memberId } = params;

  const [state, setState] = useState<'loading' | 'loaded' | 'error'>('loading');
  const [appointments, setAppointments] = useState<{
    upcoming: SanitizedCoreAppointment[];
    past: SanitizedCoreAppointment[];
  } | null>(null);

  const selectedAppointment =
    appointments?.upcoming.find((a) => a.id === Number(appointmentId)) ||
    appointments?.past.find((a) => a.id === Number(appointmentId));

  useEffect(() => {
    const eventsNames = getTrackingEventsByMemberOrSelf(!!memberId);
    MixpanelClient.trackEvent({
      eventName: eventsNames.list,
      properties: { field: 'ExistingAppointmentsPage', memberId },
    });

    (async () => {
      setState('loading');
      setAppointments(null);

      if (!getAuth()) {
        logErrorAndReportToHoneybadger({
          error: 'Attempted browsing Existing Appointments without login',
        });
        redirect('/login');
        return;
      }

      try {
        const appointments = await schedulingV3Client.listAppointments({
          memberId: memberId ? Number(memberId) : getAuth()?.id || 0,
        });

        if (appointments) {
          const upcoming = appointments.filter(
            (appointment) => appointment.future && !appointment.canceled
          );
          const past = appointments.filter(
            (appointment) => !appointment.future && !appointment.canceled
          );
          setAppointments({ upcoming, past });
          setState('loaded');
        } else {
          throw new Error('Existing Appointments: cannot load appointments');
        }
      } catch (error) {
        logErrorAndReportToHoneybadger({ error });
        setState('error');
      }
    })();
  }, [memberId]);

  const renderSlot = (appointment: SanitizedCoreAppointment) => {
    const updatedParams = {
      ...params,
      appointmentId: appointment.id,
    };
    const detailPattern = matchPath(RouteConstants.appointmentsForMe, location.pathname)
      ? RouteConstants.appointmentDetailsForMe
      : RouteConstants.appointmentDetailsForMember;

    return (
      <li key={appointment.id}>
        <h3>
          <Link to={generatePath(detailPattern, updatedParams)}>
            {appointment.displayText.split('[')[0]}
          </Link>
        </h3>
        <ul>
          <li>{appointment.startAtUTC}</li>
          <li>{appointment.provider?.displayName}</li>
        </ul>
      </li>
    );
  };

  const rootPattern = matchPath(RouteConstants.appointmentDetailsForMe, location.pathname)
    ? RouteConstants.appointmentsForMe
    : RouteConstants.appointmentsForMember;

  const yourOrMemberAppointmentLabel = memberId ? 'Member Appointments' : 'Your Appointments';
  const closeUrl = selectedAppointment ? generatePath(rootPattern, params) : '';

  return (
    <div>
      <Helmet>
        <title>Appointments - Enara</title>
      </Helmet>
      <h1 style={{ display: window.location?.hostname === 'localhost' ? undefined : 'none' }}>
        <Link to='/'>Menu</Link> {'>'}{' '}
        {selectedAppointment ? (
          <>
            <Link to={closeUrl}>{yourOrMemberAppointmentLabel}</Link>{' '}
            {`> ${selectedAppointment.displayText.split('[')[0]}`}
          </>
        ) : (
          yourOrMemberAppointmentLabel
        )}
      </h1>
      {selectedAppointment && (
        <SelectedAppointment
          appointment={selectedAppointment}
          onClose={() => {
            navigate(`${closeUrl}`);
          }}
        />
      )}
      {!selectedAppointment &&
        (state === 'loading' ? (
          <div>Loading...</div>
        ) : !appointments ? (
          <div>Appointments couldn't be loaded</div>
        ) : (
          <div>
            <h2>Upcoming</h2>
            <ul>
              {appointments.upcoming.length === 0 ? (
                <li>No appointments</li>
              ) : (
                appointments.upcoming.map(renderSlot)
              )}
            </ul>
            <h2>Past</h2>
            <ul>
              {appointments.past.length === 0 ? (
                <li>No appointments</li>
              ) : (
                appointments.past.slice(0, 3).map(renderSlot)
              )}
            </ul>
          </div>
        ))}
    </div>
  );
};
