import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import './visit-warning.css';

const VisitWarning: FC = () => {
  return (
    <Stack className='visit-warning-box'>
      <Typography>This class counts as your nutrition/exercise visit for the week.</Typography>
    </Stack>
  );
};

export default VisitWarning;
