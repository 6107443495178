import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { useFeatures } from '../../contexts/features/context';
import {
  DATE_TIMEZONE,
  DATE_TIME_DAY_TITLE_FORMAT,
  DATE_TIME_MILITARY_TIME_FORMAT,
} from '../../helpers/time';
import { Features } from '../../lib/features/client';
import { DailyAvailability } from '../../lib/scheduling/types';
import { StyledTheme } from '../../theme';
import TimeSlot from './TimeSlot';

type Props = {
  actualDate: DateTime | null;
  selectedSlot: string | null;
  dailyAvailability: DailyAvailability | null;
  onSelectSlot: (day: string | null) => void;
};

const TimeSlotsContainer: FC<Props> = ({
  actualDate,
  selectedSlot,
  dailyAvailability,
  onSelectSlot,
}) => {
  const { featuresState } = useFeatures();

  const timeSlots = featuresState[Features.MembersWebAppHideForbiddenDates]?.enabled
    ? dailyAvailability?.times.filter(({ allowed }) => allowed)
    : dailyAvailability?.times;

  return (
    timeSlots && (
      <div className='time-slots-container'>
        {actualDate && (
          <div className='time-slots-container-title'>
            <div>
              <Typography variant='h4'>
                {actualDate.toFormat(DATE_TIME_DAY_TITLE_FORMAT)}{' '}
                {selectedSlot &&
                  ` - ${DateTime.fromISO(selectedSlot).toFormat(DATE_TIME_MILITARY_TIME_FORMAT)}`}
              </Typography>
            </div>
            <Typography variant='h4' style={{ color: StyledTheme.colors.green }}>
              {`Time shown in ${DateTime.now().toFormat(DATE_TIMEZONE)}`}
            </Typography>
          </div>
        )}

        <div className='time-slots-radio-buttons'>
          {timeSlots.map(({ time }) => (
            <TimeSlot
              key={`idx-${time}`}
              day={time}
              isSelected={selectedSlot === time}
              onSelectSlot={onSelectSlot}
            />
          ))}
        </div>
      </div>
    )
  );
};

export default TimeSlotsContainer;
